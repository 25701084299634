import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { loginUser, logoutUser, setCustomMode } from '../../actions';
import Header from '../header';
import {
  Homepage,
  CategoryPage,
  CardItemPage,
  CustomMotionPage,
  CustomCardItemPage,
  FormPage,
  NotFoundPage,
  SearchPage,
  AccountPage,
  ThanksPage,
  PrivacyPolicyPage,
} from '../pages';
import VideoPopup from '../video-popup';
import Footer from '../footer';
import ChatWidget from '../whatsapp-chat';
import CardService from '../../services/cards-service';
import ErrorBoundry from '../error-boundry';
import MobilePreview from '../mobile-preview';
import FreeStatusPage from '../pages/free-status-page';

class App extends Component {
  cardService = new CardService();

  state = {
    preview: true,
  };

  componentDidMount = () => {
    this.checkUserLogin();

    setTimeout(() => {
      this.setState({ preview: false });
    }, 2000);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser.token !== this.props.currentUser.token) {
      this.checkUserLogin();
    }

    // scroll to top after change page
    const locationChanged = this.props.location !== prevProps.location;

    if (locationChanged) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      // document.querySelector('.menu-slide, .slide');
    }
  }

  checkUserLogin = async () => {
    const token = localStorage.token;
    if (token) {
      await this.cardService
        .getUser(token)
        .then((res) => {
          // console.log('USER RES', res);
          this.props.loginUser({
            email: res.email,
            token: token,
          });
        })
        .catch((err) => {
          console.log('Get user error', err);
          this.props.logoutUser();
        });
    }
  };

  render() {
    const { currentUser, isCustomMode, setCustomMode, location, history } = this.props;

    return (
      <div className={isCustomMode ? 'ihtsy-app custom-motion-mode' : 'ihtsy-app'}>
        <MobilePreview open={this.state.preview} />
        <Header location={location} history={history} />
        <main id="main">
          <ErrorBoundry>
            <Switch>
              <Route path="/" exact component={Homepage} />
              <Route
                path="/account-login"
                exact
                render={() => <FormPage title="Account Login" form="login" />}
              />
              <Route
                path="/registration"
                exact
                render={() => <FormPage title="Registration" form="register" />}
              />
              <Route
                path="/contact-us"
                exact
                render={() => <FormPage title="Contact Us" form="contact" />}
              />
              <Route
                path="/lost-password"
                exact
                render={() => <FormPage title="Lost password" form="lost-password" />}
              />
              {/*<Route */}
              {/*  path='/custom-motion' */}
              {/*  exact */}
              {/*  render={(props) => {*/}
              {/*    setCustomMode();*/}
              {/*    return <CustomMotionPage {...props}/>*/}
              {/*  }}*/}
              {/*/>*/}
              {/*<Route path='/free-status' exact render={() => <FreeStatusPage />} />*/}
              <Route path="/search/" exact component={SearchPage} />
              <Route
                path="/account"
                exact
                render={(props) => {
                  if (currentUser) {
                    return <AccountPage />;
                  } else {
                    return <Redirect to="/account-login" />;
                  }
                }}
              />
              <Route
                path="/category-of-card/:category/"
                exact
                component={CategoryPage}
                key={window.location.pathname}
              />
              <Route
                path="/card/:card/"
                exact
                component={CardItemPage}
                key={window.location.pathname}
              />
              <Route
                path="/custom-card/:customCard/"
                exact
                key={window.location.pathname}
                render={(props) => {
                  setCustomMode();
                  return <CustomCardItemPage {...props} />;
                }}
              />
              <Route path="/404" exact component={NotFoundPage} />
              <Route path="/thank-you" exact component={ThanksPage} />
              <Route path="/privacy-policy" exact component={PrivacyPolicyPage} />
              <Route component={NotFoundPage} />
            </Switch>
          </ErrorBoundry>
        </main>
        <VideoPopup />
        <Footer />
        <ChatWidget />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    isCustomMode: state.isCustomMode,
  };
};

const mapDispatchToProps = {
  loginUser,
  logoutUser,
  setCustomMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
