import React, { Component } from 'react';
import FreeStatusList from '../free-status-list';

class FreeStatusPage extends Component {
  render() {
    return <FreeStatusList />;
  }
}

export default FreeStatusPage;
