import React from 'react';
import CategoryList from '../category-list';

const CategoryPage = (props) => {
  const sub = new URLSearchParams(props.history.location.search).get('sub');

  return (
    <>
      <CategoryList
        category={props.match.params.category || ''}
        subcategory={sub}
        history={props.history}
      />
    </>
  );
};

export default CategoryPage;
