import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import CardService from '../../services/cards-service';
import './card-buy-block.scss';
import ConfirmPopup from './ConfirmPopup';
import BuyingPopup from './BuyingPopup';
import DraftPopup from './DraftPopup';

class CardBuyBlock extends Component {
  cardService = new CardService();
  state = {
    activePopup: '',
    stripePromise: '',
    email: '',
    draft_email: '',
    draftEmailStatus: false,
    draftSuccess: true,
    draftLoading: false,
    loadingStripe: false,
    adminBuyEmails: [
      'eigerzelig@gmail.com',
      'danylo.podopryhora25@gmail.com',
      'orders@ihopetoseeyou.com',
      'maksym.baranovskyi@itechcraft.com',
    ],
    // pubKey: 'pk_test_51HCVkIBJrCqZuBQfcOeTF9Xzocu4UwSGvTSlrdzIao8oiDipG2OE5PXrQ4PGukqV5P1MnHXKLP2UwiK7irZq2SR8002OdmZEK6',
    // secretKey: 'sk_test_51HCVkIBJrCqZuBQfOQ1yKq5aVLPEpmttf0NJCHuhEBb1RdPg7bDX0lH9BzoPWO1p5Ds2gsOtJnYWwVVmxOmoFCYC00wdhmldrb',
    pubKey:
      'pk_live_51HCVkIBJrCqZuBQfrE6pELfyI2AzeLGfa2UgKTWIOTyteIBtcex016yipYAGAQdcU87dNiPwymY2YyVtJy3GRXVQ00pFe4ygJo',
    secretKey:
      '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',
    btnDisable: false,
    isWhatsAppNotificationEnabled: false,
    whatsappPhoneNumber: null,
    stripeError: false,
  };
  confirmPopup = React.createRef();
  buyPopup = React.createRef();
  draftPopup = React.createRef();

  componentDidMount() {
    if (this.props.currentUser && this.props.currentUser.email) {
      this.setState({
        email: this.props.currentUser.email,
      });
    }

    this.initLoadStripe();
  }

  initLoadStripe = async () => {
    const { pubKey } = this.state;

    const newStripePromise = await loadStripe(pubKey);

    this.setState({
      stripePromise: newStripePromise,
    });
  };

  openPopup = (e, popup) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      activePopup: popup,
    });
  };

  closePopup = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      activePopup: '',
      stripeError: false,
    });
  };

  saveDraft = async (e) => {
    if (e) {
      e.preventDefault();
    }
    const { draft_email } = this.state;

    this.setState({
      draftLoading: true,
    });

    if (draft_email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      const { product } = this.props;
      let newOrder = {
        email: draft_email,
        card_id: product.cardId,
        fields: [...product.fields],
      };

      if (product.audio_id) {
        newOrder.audio_id = '' + product.audio_id;
      }

      newOrder.fields.forEach((item) => {
        item.font_id = item.font.id;
      });

      await this.cardService
        .setDraft(newOrder)
        .then((res) => {
          // console.log('result', res);
          this.setState({
            draftEmailStatus: true,
            draftLoading: false,
          });
        })
        .catch((err) => {
          console.log('Error', err);
          this.setState({
            draftSuccess: false,
            draftLoading: false,
          });
        });
    }
  };

  inputChangeHandler = (e) => {
    const name = e.target.name,
      value = e.target.value;

    this.setState({
      [name]: value,
    });
  };

  numberRecalc = (num, proportion) => {
    return Math.round(num * proportion);
  };

  setOrder = async (paymentData) => {
    const { product, onSuccess, setEmail } = this.props;
    const { email } = this.state;
    this.setLoadingStripe(true);
    let newOrder = {
      email,
      card_id: '' + product.cardId,
      stripe_details: JSON.stringify(paymentData),
      fields: [...product.fields],
    };

    this.setState({
      btnDisable: true,
    });

    if (product.audio_id) {
      newOrder.audio_id = '' + product.audio_id;
    }

    if (product.second_image) {
      newOrder.secondImageUrl = '' + product.second_image.url;
    }

    if (this.state.isWhatsAppNotificationEnabled) {
      newOrder.is_whatsapp_notification_enabled = this.state.isWhatsAppNotificationEnabled;
      newOrder.whatsapp_phone = this.state.whatsappPhoneNumber;
    }

    if (product.logoDetails?.image) {
      newOrder.logoDetails = {
        ...product.logoDetails,
        image: product.logoDetails.image,
        width: this.numberRecalc(product.logoDetails.width, product.logo_proportion.x),
        height: this.numberRecalc(product.logoDetails.height, product.logo_proportion.y),
        posX: this.numberRecalc(product.logoDetails.posX, product.logo_proportion.x),
        posY: this.numberRecalc(product.logoDetails.posY, product.logo_proportion.y),
      };
    }

    newOrder.fields.forEach((item) => {
      item.font_id = item.font.id;
    });

    // immediatly show Thanks message
    // onSuccess();

    await this.cardService
      .setOrder(newOrder)
      .then((res) => {
        console.log('result', res);

        this.setLoadingStripe(false);
        if (res.status === 'success') {
          onSuccess();
          setEmail(this.state.email);
          sessionStorage.clear();
          this.setState({
            btnDisable: false,
            stripeError: false,
          });
        } else {
          this.setState({
            btnDisable: false,
            stripeError: res.error,
          });
        }
      })
      .catch((err) => {
        this.setLoadingStripe(false);
        console.log('Error', err);
        this.setState({
          btnDisable: false,
        });
      });
  };

  setLoadingStripe = (flag) => {
    this.setState({
      loadingStripe: flag,
    });
  };

  whatsAppNotificationHandler = () => {
    const { isWhatsAppNotificationEnabled } = this.state;
    this.setState((state) => ({
      isWhatsAppNotificationEnabled: !isWhatsAppNotificationEnabled,
    }));
  };

  whatsAppPhoneNumberHandler = (value) => {
    this.setState((state) => ({
      whatsappPhoneNumber: value,
    }));
  };

  render() {
    const {
      // product,
      draft,
      btnTitle,
      price,
      cardName,
      noteText = '',
      currentUser,
    } = this.props;

    const {
      activePopup,
      stripePromise,
      email,
      draft_email,
      draftEmailStatus,
      draftSuccess,
      draftLoading,
      loadingStripe,
      adminBuyEmails,
      btnDisable,
    } = this.state;

    // console.log('product', product);

    let popup = '';
    switch (activePopup) {
      case 'confirm':
        popup = (
          <ConfirmPopup
            ref={(r) => (this.confirmPopup.current = r)}
            nextPopup={(e) => this.openPopup(e, 'buying')}
            closePopup={this.closePopup}
          />
        );
        break;
      case 'buying':
        popup = (
          <BuyingPopup
            ref={(r) => (this.buyPopup.current = r)}
            stripePromise={stripePromise}
            email={email}
            inputChangeHandler={this.inputChangeHandler}
            currentUser={currentUser}
            setOrder={this.setOrder}
            loadingStripe={loadingStripe}
            setLoadingStripe={this.setLoadingStripe}
            price={price}
            cardName={cardName}
            isWhatsAppNotificationEnabled={this.state.isWhatsAppNotificationEnabled}
            whatsAppNotificationHandler={this.whatsAppNotificationHandler}
            whatsAppPhoneNumberHandler={this.whatsAppPhoneNumberHandler}
            whatsAppPhone={this.state.whatsappPhoneNumber}
            closePopup={this.closePopup}
            stripeError={this.state.stripeError}
          />
        );
        break;
      case 'draft':
        popup = (
          <DraftPopup
            draft_email={draft_email}
            inputChangeHandler={this.inputChangeHandler}
            draftEmailStatus={draftEmailStatus}
            saveDraft={this.saveDraft}
            draftSuccess={draftSuccess}
            loading={draftLoading}
            closePopup={this.closePopup}
            ref={(r) => (this.draftPopup.current = r)}
          />
        );
        break;
      default:
        popup = '';
    }

    return (
      <div className="btn-holder popup-holder">
        <div className="editor-btn-holder">
          {noteText ? <strong className="estimation-note">{noteText}</strong> : ''}
          {draft ? (
            <a
              href="/"
              className="popup-opener draft-link"
              data-popup-opener="draft"
              onClick={(e) => this.openPopup(e, 'draft')}
            >
              Save for Later
              <img src={'/assets/images/icon-21.png'} alt="save" />
            </a>
          ) : (
            ''
          )}
          <strong className="price">
            <span className="woocommerce-Price-amount amount">
              {price ? (
                <>
                  <span className="woocommerce-Price-currencySymbol">&#36;</span>
                  {price}
                </>
              ) : (
                'Free'
              )}
            </span>
          </strong>
          <button
            type="button"
            className="btn btn-fill popup-opener"
            data-popup-opener="confirm"
            onClick={(e) => {
              this.openPopup(e, 'confirm');
              // this.createStripeOrder();
            }}
          >
            {btnTitle}
            <i className="fas fa-shopping-cart"></i>
          </button>
        </div>
        {adminBuyEmails.includes(email) ? (
          //     (true)
          <>
            <br />
            <br />
            <button
              type="button"
              className="btn btn-fill popup-opener"
              data-popup-opener="confirm"
              onClick={(e) => this.setOrder({ testPurchase: true })}
              disabled={btnDisable}
            >
              Buy Test Card
            </button>
          </>
        ) : (
          ''
        )}
        {popup}
      </div>
    );
  }
}

// export default CardBuyBlock;

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps)(CardBuyBlock);
