import React from 'react';

import './error-message.scss';

const ErrorMessage = (props) => {
  return (
    <div className="error-block">
      <div className="container">
        <h2>Something goes wrong.</h2>
        <p>Please reload the page or try again later.</p>
      </div>
    </div>
  );
};

export default ErrorMessage;
