import React from 'react';

function CardTextField({ item, index, hebrewFont, englishFont }) {
  const {
    text,
    text_alignment,
    text_position_x,
    text_position_y,
    text_rotation,
    font_color,
    font_size,
    text_lang,
    initial_text_lang,
    font,
  } = item;

  const direction = text_lang === 'hebrew' ? 'rtl' : 'ltr';
  const defaultFont = text_lang === 'hebrew' ? hebrewFont : englishFont;
  const fontFamily = initial_text_lang === text_lang ? font : defaultFont;
  const currentFont = initial_text_lang ? fontFamily : font;

  const textStyle = {
    userSelect: 'none',
    fontSize: font_size,
    // fontFamily: currentFont.name.replace('.', ''),
    textAnchor: text_alignment,
    direction: text_lang ? direction : 'rtl',
  };

  async function loadFont(name, url) {
    const font = new FontFace(name.replace('.', ''), `url(${url})`);
    await font.load().catch((err) => console.log('Font error', err));
    document.fonts.add(font);
  }

  loadFont(currentFont.name, currentFont.fullFileUrl);

  // console.log('textStyle', textStyle)
  const defsStyle = `
    @font-face {
      font-family: ${currentFont.name.replace('.', '')};
      src: url(${currentFont.fullFileUrl});
    }
  `;

  return (
    <text
      x={text_position_x}
      y={text_position_y}
      fill={font_color}
      fontSize={font_size}
      textAnchor={text_alignment}
      transform={`rotate(${text_rotation} ${text_position_x} ${text_position_y})`}
      fontFamily={currentFont.name.replace('.', '')}
      draggable="true"
      style={textStyle}
    >
      <tspan style={{ direction: 'ltr' }}>
        <tspan
        // style={{fontFamily: textSpanFomtFamily}}
        >
          {/* {textSpan} */}
        </tspan>
        {text}
      </tspan>
      <defs>
        <style type="text/css">{defsStyle}</style>
      </defs>
    </text>
  );
}

export default CardTextField;
