import React, { Component } from 'react';
import CustomCardsList from '../custom-cards-list';
import { connect } from 'react-redux';
import { setCustomMode, unsetCustomMode } from '../../actions';

class CustomMotionPage extends Component {
  componentWillUnmount() {
    const { unsetCustomMode } = this.props;
    unsetCustomMode();
  }

  render() {
    return <CustomCardsList />;
  }
}

const mapStateToProps = (state) => {
  return {
    isCustomMode: state.isCustomMode,
  };
};

const mapDispatchToProps = {
  setCustomMode,
  unsetCustomMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomMotionPage);
