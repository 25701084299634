import React from 'react';
import LoginForm from '../login-form';
import RegisterForm from '../register-form';
import ContactForm from '../contact-form';
import LostPasswordForm from '../lost-password-form';
import DefaultPageTemplate from './default-page-template';

const FormPage = ({ title = '', form }) => {
  let activeForm = <ContactForm />;

  switch (form) {
    case 'login':
      activeForm = <LoginForm />;
      break;
    case 'register':
      activeForm = <RegisterForm />;
      break;
    case 'contact':
      activeForm = <ContactForm />;
      break;
    case 'lost-password':
      activeForm = <LostPasswordForm />;
      break;
    default:
      activeForm = <ContactForm />;
  }

  return <DefaultPageTemplate title={title} inner={activeForm} />;
};

export default FormPage;
