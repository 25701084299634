import React from 'react';
import LazyLoad, { forceCheck } from 'react-lazyload';

const EditorPreview = () => {
  // init LazyLoad if in viewport
  forceCheck();

  return (
    <section
      className="editor-preview-section"
      style={{ backgroundImage: 'url(assets/images/img-21.png)' }}
    >
      <div className="container">
        <div className="section-heading">
          <h2 className="section-title">
            <span className="marked-text">Easy-to-use</span> User Interface
          </h2>
          <div className="heading-note">
            <p>Edit your desired template within minutes</p>
          </div>
        </div>
        <div className="editor-preview-block">
          <LazyLoad throttle={200} height={800} once>
            <img src={'/assets/images/img-12.png'} alt="Editor" className="preview-img d-lg" />
          </LazyLoad>
          <LazyLoad throttle={200} height={800} once>
            {/* <img src={"/assets/images/img-31.png"} alt="Editor" className="preview-img d-sm"/> */}
            <img src={'/assets/images/img-31-sm.png'} alt="Editor" className="preview-img d-sm" />
          </LazyLoad>
          <LazyLoad throttle={200} height={100} once>
            <img src={'/assets/images/icon-08.png'} alt="descr" className="decor decor-top" />
          </LazyLoad>
          <LazyLoad throttle={200} height={100} once>
            <img src={'/assets/images/icon-09.png'} alt="descr" className="decor decor-left" />
          </LazyLoad>
          <LazyLoad throttle={200} height={100} once>
            <img src={'/assets/images/icon-10.png'} alt="descr" className="decor decor-right" />
          </LazyLoad>
        </div>
        <div className="custom-delimiter"></div>
      </div>
    </section>
  );
};

export default EditorPreview;
