import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  setActiveCategory,
  unsetActiveCategory,
  setActiveSubcategory,
  unsetActiveSubcategory,
} from '../../actions';
import LazyLoad, { forceCheck } from 'react-lazyload';
import CardService from '../../services/cards-service';
import Preloader from '../preloader';

import './category-list.scss';
import BestSellers from '../best-sellers';
import CategoryCard from '../category-card';

class CategoryList extends Component {
  cardService = new CardService();
  state = {
    loading: true,
    activeCategory: '',
    notFound: false,
    cardsList: [],
    bestSellersList: [],
    totalCardsCount: 20,
    cardWidthClass: 'fourth-width',
    tags: [],
    sizes: [],
    languages: [],
    subCats: [],
    priceSort: ['high to low', 'low to high'],
    filters: {
      tags: [],
      size: [],
      language: [],
      price: [],
    },
    currentActiveCategory: null,
    currentActiveSubcategory: null,
    timer: 0,
  };

  componentDidMount() {
    if (this.props.categories.length) {
      this.getCategoryList(this.props.category, this.props.subcategory);
    }
    this.getQueryParams();
    this.initDropdown();
    this.timerID = setInterval(
      () =>
        this.setState((state) => ({
          ...state,
          timer: state.timer + 1,
        })),
      1000
    );
    const windowWidth = window.innerWidth;
    if (windowWidth < 768) {
      this.setState((state) => ({
        ...state,
        cardWidthClass: 'two-width',
      }));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.category !== this.props.category ||
      prevProps.subcategory !== this.props.subcategory ||
      prevProps.categories !== this.props.categories
    ) {
      if (this.props.categories.length) {
        this.getCategoryList(this.props.category, this.props.subcategory);
      }
    }

    this.initDropdown('refresh');

    if (prevState.filters !== this.state.filters) {
      // init LazyLoad if in viewport
      forceCheck();
    }
  }

  componentWillUnmount() {
    const dropOpeners = document.querySelectorAll('.custom-dropdown .custom-dropdown-opener');

    dropOpeners.forEach((item) => {
      item.removeEventListener('click', this.dropdownOpenerListener);
    });

    this.toggleActiveCategory(false);

    clearInterval(this.timerID);
  }

  toggleActiveCategory = (set, cat, sub) => {
    const { setActiveCategory, unsetActiveCategory, setActiveSubcategory, unsetActiveSubcategory } =
      this.props;

    if (set) {
      setActiveCategory(cat);
      setActiveSubcategory(sub);
    } else {
      unsetActiveCategory();
      unsetActiveSubcategory();
    }
  };

  getCategoryList = async (category, subcategory) => {
    this.setState((state) => ({
      cardsList: [],
      loading: true,
    }));

    const categoryData = this.findCategoryData(category);

    const subcategoryData = this.findSubCategoryData(categoryData, subcategory);

    const id = subcategory ? subcategoryData.id : categoryData.id;

    await this.cardService
      .getCardsByCategoryId(id)
      .then(async (categoryCards) => {
        let bestSellers = await this.cardService.getBestSellers(categoryData.id);

        categoryCards = categoryCards.map((elem, i) => {
          elem.local_id = '' + i;

          return elem;
        });

        this.setCardsList(categoryCards);
        this.toggleActiveCategory(true, categoryData, subcategoryData);

        let tags = subcategoryData ? subcategoryData.tags : categoryData.tags;

        this.setState((state) => ({
          activeCategory: category,
          tags: tags || [],
          loading: false,
          currentActiveCategory: categoryData,
          currentActiveSubcategory: subcategoryData && subcategoryData.name,
          bestSellersList: bestSellers,
        }));
      })
      .catch((err) => {
        console.log('Error', err);

        this.setState({
          // notFound: true,
          loading: false,
        });
      });
  };

  findCategoryData = (category) => {
    return this.props.categories.find((cat) => cat.slug === category);
  };

  findSubCategoryData = (categoryData, subcategory) => {
    return subcategory ? categoryData?.categories?.find((cat) => cat.slug === subcategory) : null;
  };

  getQueryParams = () => {
    const params = new URLSearchParams(this.props.history.location.search);

    let tags = splitArr(params.get('tags')),
      size = splitArr(params.get('size')),
      language = splitArr(params.get('language')),
      price = splitArr(params.get('price')),
      sub = splitArr(params.get('sub'));

    this.setState({
      filters: {
        tags,
        size,
        language,
        price,
        sub,
      },
    });
  };

  loadMore = (e, pageId) => {
    if (e) {
      e.preventDefault();
    }

    // const categoryCards = cardsTmplObj;

    // this.setCardsList(categoryCards);
  };

  setCardsList = (newArr) => {
    let newSizes = [],
      newLanguages = [];

    newArr.forEach((item) => {
      if (item.cardDetails) {
        if (!newSizes.includes(item.cardDetails.size)) {
          newSizes.push(item.cardDetails.size);
        }
        if (!newLanguages.includes(item.cardDetails.language)) {
          newLanguages.push(item.cardDetails.language);
        }
      }
    });

    newSizes = this.setSizeNames(newSizes);

    this.setState((state) => ({
      cardsList: [...state.cardsList, ...newArr],
      sizes: [...newSizes],
      languages: [...newLanguages],
      loading: false,
    }));
  };

  setSizeNames = (sizes) => {
    const newSizes = [];
    sizes.forEach((item) => {
      let sizeName = '';
      switch (item) {
        case '8.5 x 11':
          sizeName = 'Portrait';
          break;
        case '8.5 x 8.5':
          sizeName = 'Square';
          break;
        case '11 x 8.5':
          sizeName = 'Landscape';
          break;
      }
      let pushItem = {
        size: item,
        name: sizeName,
      };
      newSizes.push(pushItem);
    });

    return newSizes;
  };

  setCardWidth = (e, width) => {
    e.preventDefault();

    this.setState({
      cardWidthClass: width,
    });
  };

  renderCardArr = () => {
    const {
      cardsList,
      filters,
      // cardWidthClass
    } = this.state;
    let updArr = [...cardsList];

    if (filters.price.length > 0 && filters.price.includes('high to low')) {
      updArr.sort((a, b) => {
        return b.price - a.price;
      });
    } else if (filters.price.length > 0 && filters.price.includes('low to high')) {
      updArr.sort((a, b) => {
        return a.price - b.price;
      });
    } else {
      updArr.sort((a, b) => {
        return a.local_id - b.local_id;
      });
    }

    updArr = updArr.reduce((result, card, index) => {
      const { id, name, categories, price, cardDetails, tags, local_id } = card;
      let renderFlag = true;
      let newLanguages = [];
      let cardCategories = [];
      let cardTags = [];

      categories.forEach((cat) => {
        cardCategories.push(cat.name);
      });

      tags.forEach((tag) => {
        cardTags.push(tag.slug);
      });

      if (cardDetails) {
        // size filter
        if (filters.size.length > 0 && !filters.size.includes(cardDetails.size)) {
          renderFlag = false;
        }

        // tags filter
        if (filters.tags.length > 0 && !cardTags.some((r) => filters.tags.includes(r))) {
          renderFlag = false;
        }

        // get language list
        if (!newLanguages.includes(cardDetails.language)) {
          newLanguages.push(cardDetails.language);
        }
      }

      // language filter
      if (
        filters.language.length > 0 &&
        !filters.language.some((lang) => newLanguages.includes(lang))
      ) {
        renderFlag = false;
      }

      if (renderFlag) {
        result.push(
          <div
            // key={id + index}
            key={local_id + index}
            className="list-item"
          >
            <CategoryCard card={card} />
          </div>
        );
      }

      return result;
    }, []);

    if (updArr.length <= 0) {
      return (
        <div className="not-found-wrap">
          <div className="head">
            <h1>Not Found</h1>
          </div>
          <div className="content">
            <p>Sorry, but you are looking for something that isn't here.</p>
          </div>
        </div>
      );
    }

    return updArr;
  };

  initDropdown = (flag) => {
    const dropdownList = document.querySelectorAll('.custom-dropdown');

    dropdownList.forEach((holder) => {
      const opener = holder.querySelector('.custom-dropdown-opener'),
        drop = holder.querySelector('.custom-dropdown-drop'),
        dropLinks = drop.querySelectorAll('a'),
        activeClass = 'custom-dropdown-active';

      if (flag === 'refresh') {
        opener.removeEventListener('click', this.dropdownOpenerListener);
      }
      opener.addEventListener('click', this.dropdownOpenerListener);

      dropLinks.forEach((link) => {
        if (flag === 'refresh') {
          link.removeEventListener('click', clickFunction);
        }
        link.addEventListener('click', clickFunction);
      });

      if (flag === 'refresh') {
        document.removeEventListener('click', outSideClickFunction);
      }
      document.addEventListener('click', outSideClickFunction);

      function clickFunction(e) {
        e.preventDefault();

        holder.classList.remove(activeClass);
      }

      function outSideClickFunction(event) {
        var isClickInside = holder.contains(event.target);

        if (!isClickInside) {
          holder.classList.remove(activeClass);
        }
      }
    });
  };

  dropdownOpenerListener = (e) => {
    e.preventDefault();
    const activeClass = 'custom-dropdown-active',
      holder = e.target.parentElement;

    holder.classList.toggle(activeClass);
  };

  toggleFilters = async (name, val, init) => {
    const { history } = this.props;
    const { filters, activeCategory, currentActiveCategory } = this.state;
    const currentFilters = { ...filters };
    const params = new URLSearchParams(history.location.search);
    let all = false;

    if (val === 'all') {
      all = true;
    }

    switch (name) {
      case 'tags':
        params.delete('tags');
        if (currentFilters[name].includes(val)) {
          const filterIndex = currentFilters[name].indexOf(val);
          currentFilters[name] = [
            ...currentFilters[name].slice(0, filterIndex),
            ...currentFilters[name].slice(filterIndex + 1),
          ];
        } else {
          currentFilters[name].push(val);
        }
        if (currentFilters[name].length > 0) {
          params.append('tags', [...currentFilters[name]]);
        }
        break;
      case 'size':
        params.delete('size');
        if (currentFilters[name].includes(val) || all) {
          currentFilters[name] = [];
        } else {
          currentFilters[name][0] = val;
          params.append('size', [...currentFilters[name]]);
        }
        break;
      case 'language':
        params.delete('language');
        if (currentFilters[name].includes(val) || all) {
          currentFilters[name] = [];
        } else {
          currentFilters[name][0] = val;
          params.append('language', [...currentFilters[name]]);
        }
        break;
      case 'sub':
        if (!init) {
          params.delete('tags');
          currentFilters['tags'] = [];
          params.delete('size');
          currentFilters['size'] = [];
          params.delete('language');
          currentFilters['language'] = [];
          params.delete('price');
          currentFilters['price'] = [];
        }

        params.delete('sub');
        params.append('sub', val);
        break;
      case 'price':
        params.delete('price');
        if (currentFilters[name].includes(val) || all) {
          currentFilters[name] = [];
        } else {
          currentFilters[name][0] = val;
          params.append('price', [...currentFilters[name]]);
        }
        break;
      default:
        currentFilters[name] = [val];
    }

    this.setState(
      (state) => ({
        filters: { ...currentFilters },
      }),
      () => {
        history.push({ search: params.toString() });
      }
    );
  };

  render() {
    const {
      loading,
      notFound,
      // cardsList,
      // totalCardsCount,
      filters,
      tags,
      languages,
      priceSort,
      sizes,
      currentActiveCategory,
      currentActiveSubcategory,
      cardWidthClass,
    } = this.state;

    // init LazyLoad if in viewport
    // forceCheck();

    if (notFound) {
      return (
        <>
          <Redirect to="/404" />
        </>
      );
    } else if (loading) {
      return (
        <>
          <Preloader />
        </>
      );
    }

    if (this.state.timer >= 3) {
      clearInterval(this.timerID);
    }

    return (
      <section className="listing-section">
        <div className="container">
          {currentActiveCategory ? (
            <div className="category-heading">
              <div className="category-title-holder">
                <h1
                  className={
                    currentActiveCategory.categories && currentActiveCategory.categories.length > 0
                      ? ''
                      : 'single-category'
                  }
                >
                  {currentActiveCategory.name}
                </h1>
                {currentActiveCategory &&
                currentActiveCategory.categories &&
                currentActiveCategory.categories.length > 0 ? (
                  <div className="subcategory-select-holder">
                    <div
                      className={
                        currentActiveSubcategory
                          ? 'custom-dropdown subcategory-dropdown fixed-active'
                          : 'custom-dropdown subcategory-dropdown'
                      }
                    >
                      <a
                        href="/"
                        className="custom-dropdown-opener custom-dropdown-opener-changeable active-item"
                      >
                        {currentActiveSubcategory ? currentActiveSubcategory : 'All'}
                      </a>
                      <div className="custom-dropdown-drop subcategory-drop">
                        <ul>
                          {currentActiveCategory.categories &&
                            currentActiveCategory.categories.length > 0 &&
                            currentActiveCategory.categories.map((item, i) => {
                              return (
                                <li key={item.id + i}>
                                  <a
                                    href="/"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.toggleFilters('sub', item.slug);
                                    }}
                                  >
                                    {item.name}
                                  </a>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="category-filters">
                <a href="/" className="clear-filters" style={{ display: 'none' }}>
                  Clear Filters
                </a>
                <span>
                  <strong className="filter-title">Filters:</strong>
                </span>
                <ul className="filters-list">
                  <li>
                    <div
                      className={
                        filters.size.length > 0
                          ? 'custom-dropdown subcategory-dropdown fixed-active'
                          : 'custom-dropdown subcategory-dropdown'
                      }
                    >
                      <a
                        href="/"
                        onClick={(e) => e.preventDefault}
                        className="custom-dropdown-opener custom-dropdown-opener-changeable active-item"
                      >
                        {filters.size.length > 0
                          ? sizes.find((x) => x.size === filters.size[0]).name || filters.size[0]
                          : 'Size'}
                      </a>
                      <div className="custom-dropdown-drop subcategory-drop">
                        <ul>
                          <li>
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                this.toggleFilters('size', 'all');
                              }}
                            >
                              All sizes
                            </a>
                          </li>
                          {sizes.map((item, i) => {
                            return (
                              <li key={item + i}>
                                <a
                                  href="/"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.toggleFilters('size', item.size);
                                  }}
                                >
                                  {item === 'print' ? (
                                    <>
                                      {item.name}
                                      <svg
                                        className="svg-drop-icon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="22"
                                        viewBox="0 0 16 22"
                                      >
                                        <defs>
                                          <style>{`
                                                .cls-1 {
                                                  fill: none;
                                                  stroke: #191919;
                                                }
                                                `}</style>
                                        </defs>
                                        <g id="Group_283" transform="translate(-821 -255)">
                                          <g id="Rectangle_123" className="cls-1">
                                            <path
                                              d="M0 0H16V22H0z"
                                              transform="translate(821 255)"
                                            />
                                            <path
                                              fill="none"
                                              d="M0.5 0.5H15.5V21.5H0.5z"
                                              transform="translate(821 255)"
                                            />
                                          </g>
                                          <path
                                            id="Path_109"
                                            d="M6090.752 850.621h9.278"
                                            className="cls-1"
                                            transform="translate(-5266.392 -591.242)"
                                          />
                                          <path
                                            id="Path_110"
                                            d="M6090.752 850.621h9.278"
                                            className="cls-1"
                                            transform="translate(-5266.392 -585.121)"
                                          />
                                          <path
                                            id="Path_111"
                                            d="M6090.752 850.621h9.278"
                                            className="cls-1"
                                            transform="translate(-5266.392 -579)"
                                          />
                                        </g>
                                      </svg>
                                    </>
                                  ) : (
                                    <>
                                      {item.name}
                                      <svg
                                        className="svg-drop-icon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                      >
                                        <defs>
                                          <style>{`
                                                .cls-1 {
                                                  fill: none;
                                                  stroke: #191919;
                                                }
                                                `}</style>
                                        </defs>
                                        <g id="Group_282" transform="translate(-786 -258)">
                                          <g id="Rectangle_122" className="cls-1">
                                            <path
                                              d="M0 0H16V16H0z"
                                              transform="translate(786 258)"
                                            />
                                            <path
                                              fill="none"
                                              d="M0.5 0.5H15.5V15.5H0.5z"
                                              transform="translate(786 258)"
                                            />
                                          </g>
                                          <path
                                            id="Path_112"
                                            d="M6090.752 850.621h9.278"
                                            className="cls-1"
                                            transform="translate(-5301.392 -588.501)"
                                          />
                                          <path
                                            id="Path_113"
                                            d="M6090.752 850.621h9.278"
                                            className="cls-1"
                                            transform="translate(-5301.392 -584.621)"
                                          />
                                          <path
                                            id="Path_114"
                                            d="M6090.752 850.621h9.278"
                                            className="cls-1"
                                            transform="translate(-5301.392 -580.741)"
                                          />
                                        </g>
                                      </svg>
                                    </>
                                  )}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div
                      className={
                        filters.language.length > 0
                          ? 'custom-dropdown subcategory-dropdown fixed-active'
                          : 'custom-dropdown subcategory-dropdown'
                      }
                    >
                      <a
                        href="/"
                        onClick={(e) => e.preventDefault}
                        className="custom-dropdown-opener custom-dropdown-opener-changeable active-item"
                      >
                        {filters.language.length > 0 ? filters.language[0] : 'Language'}
                      </a>
                      <div className="custom-dropdown-drop subcategory-drop">
                        <ul>
                          <li>
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                this.toggleFilters('language', 'all');
                              }}
                            >
                              All languages
                            </a>
                          </li>
                          {languages.map((item, i) => {
                            return (
                              <li key={item + i}>
                                <a
                                  href="/"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.toggleFilters('language', item);
                                  }}
                                >
                                  {item}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div
                      className={
                        filters.price.length > 0
                          ? 'custom-dropdown subcategory-dropdown fixed-active'
                          : 'custom-dropdown subcategory-dropdown'
                      }
                    >
                      <a
                        href="/"
                        onClick={(e) => e.preventDefault}
                        className="custom-dropdown-opener custom-dropdown-opener-changeable active-item"
                      >
                        {filters.price.length > 0 ? filters.price[0] : 'Price'}
                      </a>
                      <div className="custom-dropdown-drop subcategory-drop">
                        <ul>
                          <li>
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                this.toggleFilters('price', 'all');
                              }}
                            >
                              Default
                            </a>
                          </li>
                          {priceSort.map((item, i) => {
                            return (
                              <li key={item + i}>
                                <a
                                  href="/"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.toggleFilters('price', item);
                                  }}
                                >
                                  {item}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="category-tags-holder">
                {tags.length > 0 ? (
                  <ul>
                    {tags.map((tag, i) => {
                      return (
                        <li key={tag.id + i}>
                          <a
                            href="/"
                            className={
                              filters['tags'].includes(tag.slug)
                                ? 'btn btn-outline-secondary active'
                                : 'btn btn-outline-secondary'
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              this.toggleFilters('tags', tag.slug);
                            }}
                          >
                            {tag.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  ''
                )}
              </div>
              <div className="category-view-holder">
                <strong className="category-view-title">Views:</strong>
                <ul className="category-view-list">
                  <li className="full-width-view">
                    <a
                      href="/"
                      className={cardWidthClass === 'full-width' ? 'active' : ''}
                      onClick={(e) => this.setCardWidth(e, 'full-width')}
                    >
                      <img src={'/assets/images/icon-15.png'} alt="descr" />
                    </a>
                  </li>
                  <li className="third-width-view">
                    <a
                      href="/"
                      className={cardWidthClass === 'third-width' ? 'active' : ''}
                      onClick={(e) => this.setCardWidth(e, 'third-width')}
                    >
                      <img src={'/assets/images/icon-16.png'} alt="descr" />
                    </a>
                  </li>
                  <li className="fourth-width-view">
                    <a
                      href="/"
                      className={cardWidthClass === 'fourth-width' ? 'active' : ''}
                      onClick={(e) => this.setCardWidth(e, 'fourth-width')}
                    >
                      <img src={'/assets/images/icon-17.png'} alt="descr" />
                    </a>
                  </li>
                  <li className="two-width-view">
                    <a
                      href="/"
                      className={cardWidthClass === 'two-width' ? 'active' : ''}
                      onClick={(e) => this.setCardWidth(e, 'two-width')}
                    >
                      <img src={'/assets/images/two-item.png'} alt="descr" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="listing-holder">
            <div className={`listing ${cardWidthClass}`}>{this.renderCardArr()}</div>
            {/* <div className="listing-load-more-holder">
              <a 
                href="/" 
                className="btn btn-outline-secondary"
                onClick={(e) => this.loadMore(e, 2)}
              >
                Load More
              </a>
              <span className="listing-counter">{cardsList.length} of {Math.max(totalCardsCount, cardsList.length)}</span>
            </div> */}
          </div>
          <BestSellers cardList={this.state.bestSellersList} />
        </div>
      </section>
    );
  }
}

function splitArr(arr) {
  if (arr) {
    arr = arr.split(',');
  } else {
    arr = [];
  }

  return arr;
}

const mapStateToProps = (state) => {
  return {
    activeCategory: state.activeCategory,
    activeSubcategory: state.activeSubcategory,
    categories: state.allCategories,
  };
};

const mapDispatchToProps = {
  setActiveCategory,
  unsetActiveCategory,
  setActiveSubcategory,
  unsetActiveSubcategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);
