import React from 'react';
import './card-logo.scss';
import { Rnd } from 'react-rnd';

function CardLogo(props) {
  const handleResize = (e, direction, ref, delta, position) => {
    const { width, height } = ref.style;

    props.setLogoSize(strToNumber(width), strToNumber(height));
    props.changeLogoPosition(position.x, position.y);
  };

  const strToNumber = (str) => {
    return Number(str.substr(0, str.length - 2));
  };

  const handleDrag = (e, d) => {
    const { x, y } = d;
    props.changeLogoPosition(x, y);
  };

  const { width, height, posX, posY, image } = props.logo;

  return (
    <Rnd
      size={{ width, height }}
      position={{ x: posX, y: posY }}
      onDrag={handleDrag}
      onResizeStop={handleResize}
      lockAspectRatio={true}
      bounds={'parent'}
      allowAnyClick={true}
      default={{
        x: 0,
        y: 0,
      }}
    >
      <div className={'dot dot-top-left'}></div>
      <div className={'dot dot-top-right'}></div>
      <div className={'dot dot-bottom-left'}></div>
      <div className={'dot dot-bottom-right'}></div>
      <img src={image} alt="" draggable={false} />
    </Rnd>
  );
}

export default CardLogo;
