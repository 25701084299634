import React, { Component } from 'react';
import CardTextField from '../card-text-field';
import CardLogo from './elements/card-logo';

class PreviewBlock extends Component {
  myRef = React.createRef();

  componentDidMount() {
    this.setProportions();
    window.addEventListener('resize', this.setProportions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setProportions);
  }

  setProportions = () => {
    const node = this.myRef.current;
    this.props.setLogoProportion(
      this.props.imageSizes.width / node.clientWidth,
      this.props.imageSizes.height / node.clientHeight
    );
  };

  render() {
    const {
      imageSizes,
      card,
      activeBgImage,
      isBgToggle,
      bgVariantsList,
      changeLogoPosition,
      setLogoSize,
    } = this.props;

    const shouldReverseImages = card?.should_reverse_images;
    let content = '';

    const mainImg = (
      <image
        className="inner-img"
        id="main-img"
        width={imageSizes.width}
        height={imageSizes.height}
        x="0"
        y="0"
        preserveAspectRatio="none"
        xlinkHref={activeBgImage}
        style={{ WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)' }}
      />
    );

    if (card.second_image) {
      const secondImg = (
        <image
          className="upd-img"
          x={card.second_image.posx}
          y={card.second_image.posy}
          preserveAspectRatio="none"
          xlinkHref={card.second_image.url}
          width={card.second_image.width}
          transform={
            card.second_image.rotation
              ? `rotate(${card.second_image.rotation} ${card.second_image.posx} ${card.second_image.posy})`
              : ''
          }
          height={card.second_image.height}
          style={{ WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)' }}
        />
      );

      if (shouldReverseImages) {
        content = (
          <>
            {mainImg}
            {secondImg}
          </>
        );
      } else {
        content = (
          <>
            {secondImg}
            {mainImg}
          </>
        );
      }
    } else {
      content = mainImg;
    }

    return (
      <div className="preview-block">
        <div className={'svg-wrapper'} style={{ position: 'relative' }} ref={this.myRef}>
          <svg
            className="svg-img"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            style={{ overflow: 'hidden', position: 'relative' }}
            width={imageSizes.width}
            height={imageSizes.height}
            // viewBox="0 0 2550 3300"
            viewBox={`0 0 ${imageSizes.width} ${imageSizes.height}`}
            preserveAspectRatio="xMidYMid meet"
          >
            {content}
            {card
              ? card.fields.map((item, i) => (
                  <CardTextField
                    key={i}
                    item={item}
                    index={i}
                    hebrewFont={card.initCardData?.cardDetails?.defaultHebrewFont}
                    englishFont={card.initCardData?.cardDetails?.defaultEnglishFont}
                  />
                ))
              : ''}
          </svg>
          {card.has_logo && card.logoDetails?.image && (
            <CardLogo
              logo={card.logoDetails}
              changeLogoPosition={changeLogoPosition}
              setLogoSize={setLogoSize}
            />
          )}
        </div>
        <div className="editor-card-note">
          <span>Watermark will not appear on the final card</span>
        </div>
        {isBgToggle ? (
          <div className="card-bg-options">
            <a href="/" className="variant-opener" onClick={this.bgVariantsToggle}>
              <span className="custom-icon">
                <span className="custom-icon-inner"></span>
              </span>
            </a>
            <div className="card-bg-option-drop">
              <ul className="card-bg-option-list">
                {bgVariantsList.map((item, i) => {
                  return (
                    <li key={item.title + i}>
                      <div
                        className={item.active ? 'img-holder active-variant' : 'img-holder'}
                        onClick={(e) => this.setActiveBg(e, i)}
                      >
                        <img className="img-variat" src={item.img} alt={item.title} />
                      </div>
                      <strong className="variant-title">{item.title}</strong>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="btn-holder"></div>
      </div>
    );
  }
}

export default PreviewBlock;
