import React from 'react';
import './thanks-popup.scss';

const ThanksPopup = (props) => (
  <div className="popup-open upd-popup-holder thanks-popup">
    <div
      className="upd-popup-inner"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          props.closePopup(e);
        }
      }}
    >
      <div className="upd-popup">
        <a href="/" className="close-popup" onClick={(e) => props.closePopup(e)}>
          Close
        </a>
        <div className="text-wrapper">
          <div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76">
              <g id="Group_731" data-name="Group 731" transform="translate(-725 -366)">
                <g
                  id="Ellipse_67"
                  data-name="Ellipse 67"
                  transform="translate(728 369)"
                  fill="#fff"
                  stroke="#b88b48"
                  stroke-width="3"
                >
                  <circle cx="35" cy="35" r="35" stroke="none" />
                  <circle cx="35" cy="35" r="36.5" fill="none" />
                </g>
                <path
                  id="Path_248"
                  data-name="Path 248"
                  d="M743.747,404l14.832,14.832,26.758-26.758"
                  fill="none"
                  stroke="#b88b48"
                  stroke-width="3"
                />
              </g>
            </svg>
          </div>
          <h4>Hooray!!!</h4>
          <p>
            Thank you! Your order is on it’s way. Please check your email (Don’t see it? Check your
            spam.)
          </p>
          <div className="rate-block">
            <p>Rate your experience</p>
            <a
              href="https://g.page/r/CRddZXk_ci--EAg/review"
              onClick={() => props.closePopup()}
              target="_blank"
              className="btn btn-fill"
            >
              Rate us on Google
            </a>
          </div>
          <p className="email">
            This is the email you entered <br />
            <span>{props.email}</span> <br />
            if its the wrong email - please call or whatsapp Customer Service at 718-612-5052
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default ThanksPopup;
