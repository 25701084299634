import React, { Component } from 'react';
import IconSearch from './icon-search';
import './search.scss';
import ResultsPopup from './results-popup';
import CardService from '../../services/cards-service';
import { Redirect } from 'react-router-dom';

export class SearchForm extends Component {
  cardService = new CardService();
  state = {
    isPopupActive: false,
    search: '',
    searchResults: null,
    redirect: null,
  };

  submitForm = (e) => {
    e.preventDefault();
    if (!this.props.isSearchActive) {
      this.props.handleOpen();
    } else {
      const { search } = this.state;

      if (search) {
        this.getSearchResults(search);
      }
    }
  };

  getSearchResults = async (search) => {
    await this.cardService.getSearchResults(search.trim()).then((res) => {
      let redirect;
      switch (res.type) {
        case 'card':
          redirect = `/card/${res.model.id}`;
          break;
        case 'category':
          if (res.model.parent_id) {
            redirect = `/category-of-card/${res.model.parent.slug}?sub=${res.model.slug}`;
          } else {
            redirect = `/category-of-card/${res.model.slug}`;
          }
          break;
        default:
          break;
      }
      this.setState((state) => ({
        search: '',
        searchResults: res,
        isPopupActive: res.type === 'empty',
        redirect,
      }));
    });
  };

  closePopup = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      isPopupActive: false,
    });
  };

  componentDidMount() {
    if (window.innerWidth >= 1024) {
      this.props.handleOpen();
    }
    window.addEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    if (window.innerWidth >= 1024 && !this.state.isOpened) {
      this.props.handleOpen();
    }
    if (window.innerWidth < 1024 && this.state.isOpened) {
      this.props.handleClose();
    }
  };

  handleInputChange = (e) => {
    this.setState((state) => ({
      search: e.target.value,
    }));
  };

  render() {
    const { isPopupActive, search, redirect } = this.state;

    if (redirect) {
      this.setState((state) => ({
        redirect: null,
      }));
      return <Redirect to={redirect} />;
    }

    return (
      <div className={`search ${this.props.isSearchActive ? 'opened' : ''}`}>
        <form onSubmit={this.submitForm}>
          <input
            type="text"
            placeholder="Search"
            className={'search-input'}
            onChange={this.handleInputChange}
            value={search}
          />
          <button type={'submit'} className={'search-submit'}>
            <IconSearch />
          </button>
        </form>
        {isPopupActive && <ResultsPopup closePopup={this.closePopup} />}
      </div>
    );
  }
}
