import React from 'react';

const NotFoundPage = () => {
  return (
    <div id="content">
      <div className="post">
        <div className="head">
          <h1>Not Found</h1>
        </div>
        <div className="content">
          <p>Sorry, but you are looking for something that isn't here.</p>
          {/* <form method="get" className="search-form" action="#">
              <fieldset>
                <input type="search" name="s" placeholder="Enter search terms…"/>
                <input type="submit" value="Search"/>
              </fieldset>
            </form> */}
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
