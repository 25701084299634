const initialState = {
  currentUser: false,
  headerPopupActive: false,
  isCustomMode: false,
  activeCategory: null,
  activeSubcategory: null,
  draftCard: null,
  categories: [],
  videoEnabled: false,
  faqEnabled: false,
  allCategories: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_USER':
      return {
        ...state,
        currentUser: action.payload ? { ...state.currentUser, ...action.payload } : true,
      };
    case 'LOGOUT_USER':
      localStorage.removeItem('token');
      return {
        ...state,
        currentUser: false,
      };
    case 'HEADER_POPUP_ACTIVE':
      return {
        ...state,
        headerPopupActive: true,
      };
    case 'HEADER_POPUP_DISABLE':
      return {
        ...state,
        headerPopupActive: false,
      };
    case 'HEADER_POPUP_TOGGLE':
      return {
        ...state,
        headerPopupActive: !state.headerPopupActive,
      };
    case 'SET_CUSTOM_MODE':
      return {
        ...state,
        isCustomMode: true,
      };
    case 'UNSET_CUSTOM_MODE':
      return {
        ...state,
        isCustomMode: false,
      };
    case 'SET_ACTIVE_CATEGORY':
      return {
        ...state,
        activeCategory: action.payload,
      };
    case 'UNSET_ACTIVE_CATEGORY':
      return {
        ...state,
        activeCategory: '',
      };
    case 'SET_ACTIVE_SUBCATEGORY':
      return {
        ...state,
        activeSubcategory: action.payload,
      };
    case 'UNSET_ACTIVE_SUBCATEGORY':
      return {
        ...state,
        activeSubcategory: '',
      };
    case 'SET_DRAFT_CARD':
      return {
        ...state,
        draftCard: action.payload,
      };
    case 'UNSET_DRAFT_CARD':
      return {
        ...state,
        draftCard: false,
      };
    case 'SET_CATEGORIES_LIST':
      return {
        ...state,
        categories: action.payload,
      };
    case 'SET_ALL_CATEGORIES_LIST':
      return {
        ...state,
        allCategories: action.payload,
      };
    case 'SET_VIDEO_AVAILABILITY':
      return {
        ...state,
        videoEnabled: action.payload,
      };
    case 'SET_FAQ_AVAILABILITY':
      return {
        ...state,
        faqEnabled: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
