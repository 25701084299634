import React, { Component } from 'react';
import Ticker from 'react-ticker';
import './card-music-block.scss';

class CardMusicBlock extends Component {
  state = {
    showList: false,
    musicChecked: false,
    playingSong: null,
    checkedSong: '',
    renderChecked: false,
  };

  componentDidMount() {
    this.setDraftSong();
  }

  setDraftSong = () => {
    const { musicList, checkedDraftMusic, setCheckedMusic } = this.props;

    for (let i = 0; i < musicList.length; i++) {
      if (musicList[i].id === checkedDraftMusic) {
        this.chooseSong(musicList[i]);

        this.setState(
          (state) => ({
            showList: false,
            musicChecked: state.checkedSong ? true : false,
            renderChecked: state.checkedSong ? true : false,
          }),
          () => {
            this.setPlaySong(null);
            if (setCheckedMusic) {
              setCheckedMusic(checkedDraftMusic);
            }
          }
        );
      }
    }
  };

  toggleList = (e) => {
    this.setState((state) => ({
      showList: !state.showList,
      musicChecked: !state.musicChecked,
    }));
  };

  setPlaySong = (song) => {
    const { playingSong } = this.state;
    let newSong = song;

    if (playingSong && newSong && playingSong.file === newSong.file) {
      newSong = null;
    }

    this.setState({
      playingSong: newSong,
    });
  };

  chooseSong = (song) => {
    // console.log('song', song)

    this.setState(
      {
        checkedSong: song,
      },
      () => {
        // setCheckedMusic(song.id);
      }
    );
  };

  render() {
    const { musicList, dropTitle = 'CHOOSE MUSIC', setCheckedMusic } = this.props;
    const { showList, musicChecked, playingSong, checkedSong, renderChecked } = this.state;

    const { cardAudios, cardFolders } = musicList;

    return (
      <div
        className={showList ? 'music-holder music-popup active' : 'music-holder music-popup'}
        data-musicprice="5"
      >
        <label>
          <span className="fake-checkbox-holder">
            <input
              type="checkbox"
              name="music"
              className="hide-input music-checkbox"
              checked={musicChecked}
              onChange={this.toggleList}
            />
            <span className="fake-checkbox"></span>
          </span>
          {dropTitle}
        </label>
        {renderChecked ? (
          <div className="current-song" style={{ display: 'block' }}>
            <strong className="song-title">{checkedSong.file}</strong>
            <span className="note">Has been added</span>
          </div>
        ) : (
          ''
        )}
        <div className="popup-block">
          <div className="popup-content">
            <div className="popup-heading">
              <h3>Select A Song</h3>
              <a
                href="/"
                className="close-popup"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState(
                    (state) => ({
                      showList: false,
                      musicChecked: state.checkedSong ? true : false,
                      renderChecked: state.checkedSong ? true : false,
                    }),
                    () => {
                      this.setPlaySong(null);
                      if (setCheckedMusic) {
                        setCheckedMusic(checkedSong.id);
                      }
                    }
                  );
                }}
              >
                <i className="fas fa-times"></i>
              </a>
            </div>
            <div className="popup-list">
              {!!cardAudios.length && (
                <ul className="audio-list">
                  {cardAudios.map((item, i) => (
                    <AudioBlock
                      key={i}
                      item={item}
                      playingSong={playingSong}
                      setPlaySong={this.setPlaySong}
                      checkedSong={checkedSong}
                      chooseSong={this.chooseSong}
                    />
                  ))}
                </ul>
              )}
              {!!cardFolders.length &&
                cardFolders.map((folder) => (
                  <div key={folder.name}>
                    <h2 className={'folder-title'}>{folder?.name}</h2>
                    <ul className="audio-list">
                      {folder?.media?.map((item, i) => (
                        <AudioBlock
                          key={i}
                          item={item}
                          playingSong={playingSong}
                          setPlaySong={this.setPlaySong}
                          checkedSong={checkedSong}
                          chooseSong={this.chooseSong}
                        />
                      ))}
                    </ul>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class AudioBlock extends Component {
  state = {
    duration: 30,
    tickerEnabled: false,
    songIsChecked: true,
    isTickerNeed: false,
  };
  audio = React.createRef();
  input = React.createRef();
  label = React.createRef();
  labelText = React.createRef();

  componentDidMount() {
    this.initPlayState();
    if (this.labelText.current.offsetWidth > this.label.current.offsetWidth) {
      this.setState((state) => ({
        ...state,
        isTickerNeed: true,
      }));
    }
  }

  toggleTicker = (e, tickerEnabled) => {
    if (this.state.isTickerNeed) {
      this.setState({
        tickerEnabled: tickerEnabled,
      });
    }
  };

  componentDidUpdate() {
    this.initPlayState();
  }

  componentWillUnmount() {
    this.props.setPlaySong(null);
  }

  initPlayState = () => {
    const { item, playingSong } = this.props;

    if (playingSong && item && item.file === playingSong.file) {
      this.audio.current.play();
    } else {
      this.audio.current.pause();
    }
  };

  onLoadHandler = (e) => {
    this.setState({
      duration: e.target.duration || 30,
    });
  };

  render() {
    const { file, fileFullUrl, name, folder } = this.props.item;
    const { duration, tickerEnabled, songIsChecked } = this.state;
    const { item, playingSong, setPlaySong, checkedSong, chooseSong } = this.props;

    let playState = 'paused';
    let blockClass = 'song-block';

    if (playingSong && item && playingSong.file === file) {
      playState = 'running';
      blockClass = 'song-block playing';
    }

    const fileNme = name ? name : file;

    return (
      <li
        onClick={(e) => {
          let updatedSongIsChecked = true;
          if (e.target.classList.contains('fake-checkbox')) {
            const checkedSongId = checkedSong.id || 0;
            if (checkedSongId === item.id) {
              updatedSongIsChecked = !songIsChecked;
            } else {
              updatedSongIsChecked = true;
            }
          }

          updatedSongIsChecked ? chooseSong(item) : chooseSong('');
          this.setState({
            songIsChecked: updatedSongIsChecked,
          });
          this.input.current.click();
          setPlaySong(item);
        }}
      >
        <div className={blockClass}>
          <figure className="chart play-btn" data-percent="100">
            <svg width="42" height="42">
              <circle
                className="outer"
                cx="21"
                cy="21"
                r="20"
                style={{
                  animation: `${duration}s linear 0s infinite normal none ${playState} show100`,
                }}
              ></circle>
            </svg>
          </figure>
          <audio
            onLoadedMetadata={this.onLoadHandler}
            loop={true}
            ref={(r) => {
              this.audio.current = r;
            }}
          >
            <source src={fileFullUrl} type="audio/mpeg" />
            Your browser does not support the <code>audio</code> element.{' '}
            <a href={fileFullUrl}>Song</a>
          </audio>
        </div>
        <label
          ref={(r) => {
            this.label.current = r;
          }}
          onMouseLeave={(e) => this.toggleTicker(e, false)}
          onMouseOver={(e) => this.toggleTicker(e, true)}
        >
          {this.state.isTickerNeed && (
            <div>
              {tickerEnabled && (
                <span className="title">
                  <Ticker offset="run-in" mode="await" move={tickerEnabled}>
                    {() => (
                      <>
                        {`${folder} - ` || ''}
                        {fileNme}
                      </>
                    )}
                  </Ticker>
                </span>
              )}
              {!tickerEnabled && <span className="title visible">{fileNme}</span>}
            </div>
          )}
          {!this.state.isTickerNeed && (
            <span>
              <span
                className="title visible"
                ref={(r) => {
                  this.labelText.current = r;
                }}
              >
                {fileNme}
              </span>
              <span
                className="calc-width"
                ref={(r) => {
                  this.labelText.current = r;
                }}
              >
                {fileNme}
              </span>
            </span>
          )}
          <span className="fake-checkbox-holder">
            Select this song
            <input
              type="radio"
              name="song"
              className="hide-input"
              value={checkedSong}
              checked={songIsChecked && checkedSong.id === item.id}
              ref={(r) => (this.input.current = r)}
              onClick={(e) => {
                e.stopPropagation();
                songIsChecked ? chooseSong(item) : chooseSong('');
              }}
              onChange={(e) => {
                e.stopPropagation();
                songIsChecked ? chooseSong(item) : chooseSong('');
              }}
            />
            <span className="fake-checkbox"></span>
          </span>
          <figure className="chart" data-percent="100">
            <svg width="157" height="4">
              <line
                className="outer"
                x1="0"
                y1="2"
                x2="157"
                y2="2"
                style={{
                  animation: `${duration}s linear 0s infinite normal none ${playState} show100`,
                }}
              />
            </svg>
          </figure>
        </label>
      </li>
    );
  }
}

export default CardMusicBlock;
