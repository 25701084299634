import { createStore } from 'redux';
import reducer from './reducers';

let enhancer;

if (process.env.NODE_ENV !== 'production') {
  enhancer = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
}

const store = createStore(reducer, enhancer);

export default store;
