import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LazyLoad, { forceCheck } from 'react-lazyload';
import CardService from '../../services/cards-service';

import './card-slider.scss';

// import Swiper core and required modules
import { Autoplay, Navigation } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';

class CardSlider extends Component {
  constructor(props) {
    super(props);
    this.prevRef = React.createRef();
    this.nextRef = React.createRef();
  }

  cardService = new CardService();
  state = {
    cardList: null,
    slides: 5,
    swiper: null,
  };

  componentDidMount() {
    this.getCardList();
    if (this.state.swiper) {
      this.state.swiper.params.navigation.prevEl = this.prevRef?.current;
      this.state.swiper.params.navigation.nextEl = this.nextRef?.current;
      this.state.swiper.navigation.init();
      this.state.swiper.navigation.update();
    }
  }

  getCardList = async () => {
    await this.cardService
      .getNewCards()
      .then((res) => {
        this.setState((state) => ({
          ...state,
          cardList: [...res, ...res],
        }));
      })
      .catch((err) => {
        console.log('CardSlider: Error get categories');
      });
  };

  render() {
    const { cardList, slides } = this.state;

    if (!cardList || cardList.length <= slides) {
      return '';
    }

    // init LazyLoad if in viewport
    forceCheck();

    return (
      <section className="upd_card-slider-section">
        <div className="container">
          <div className="section-heading">
            <h2 className="section-title">
              Celebrate Together <span className="marked-text">Wherever You Are</span>
            </h2>
            <div className="heading-note">
              <p>Create beautiful custom invitations with hundreds of designs added weekly</p>
            </div>
          </div>
        </div>
        <div className="upd_card-slider-wrapper">
          <div className="swiper-button swiper-button-prev" ref={this.prevRef}>
            prev
          </div>
          <div className="swiper-button swiper-button-next" ref={this.nextRef}>
            next
          </div>
          <Swiper
            modules={[Navigation, Autoplay]}
            slidesPerView={slides}
            loop={true}
            autoplay={{
              delay: 7000,
            }}
            navigation={{
              prevEl: this.prevRef?.current,
              nextEl: this.nextRef?.current,
            }}
            onSwiper={(swiper) => this.setState((state) => ({ ...state, swiper }))}
            className="upd_card-slider"
          >
            {cardList.map((item) => (
              <SwiperSlide key={item.id}>
                <div className="upd_card-slide" key={item.id}>
                  <div className="card-holder">
                    <LazyLoad throttle={200} height={150} once>
                      <img src={item.cardDetails.previewUrl} alt={item.name} />
                    </LazyLoad>
                    {/* <img src={item.cardDetails.previewUrl} alt={item.name}/> */}
                    <div className="overlay-block">
                      <Link to={`/card/${item.id}`} className="btn btn-fill">
                        Edit {item.name} <img src={'/assets/images/icon-14.png'} alt="edit" />
                      </Link>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    );
  }
}

export default CardSlider;
