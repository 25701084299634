import React, { Component } from 'react';
import CardService from '../../services/cards-service';
import Slider from 'react-slick';

class Banner extends Component {
  cardService = new CardService();
  state = {
    banners: [],
  };

  componentDidMount() {
    this.getBanners();
  }

  getBanners = async () => {
    await this.cardService
      .getBanners()
      .then((res) => {
        this.setState({
          banners: res,
        });
      })
      .catch((err) => console.log('Banners error', err));
  };

  render() {
    const { bgImg, bgVideo } = this.props;
    const { banners } = this.state;
    const settings = {
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      cssEase: 'linear',
      speed: 700,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <section className="banner-section upd_section">
        <div className="banner-slider">
          <div className="banner-holder">
            {banners.length && (
              <Slider {...settings}>
                {banners.map((item, i) => {
                  return (
                    <div key={i}>
                      <div
                        className="banner"
                        onClick={(e) => {
                          if (!item.link) {
                            return;
                          }

                          window.open(item.link, '__blank');
                        }}
                        style={{ backgroundImage: `url(${item.desktopBannerUrl})` }}
                      >
                        <div className="bg-video">
                          {/* {
                bgVideo
                ? <iframe title="Simcha banner" src={bgVideo} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                : ''
              } */}
                        </div>
                        <div className="container">
                          <div className="banner-content">
                            <img
                              src={'/assets/images/I-hope-to-see-you-2-upd-2.png'}
                              alt="I hope to see you"
                              className="mobile-img"
                            />
                            <h1 className="banner-title">
                              {item.marked_text || ''}{' '}
                              <span className="white-text">{item.title || ''}</span>.
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            )}

            {!banners.length && (
              <div className="banner" style={{ backgroundImage: `url(${bgImg})` }}>
                <div className="bg-video">
                  {/* {
                bgVideo
                ? <iframe title="Simcha banner" src={bgVideo} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                : ''
              } */}
                </div>
                <div className="container">
                  <div className="banner-content">
                    <img
                      src={'/assets/images/I-hope-to-see-you-2-upd-2.png'}
                      alt="I hope to see you"
                      className="mobile-img"
                    />
                    <h1 className="banner-title">
                      Digital invitations{' '}
                      <span className="white-text">for all the moments that matter</span>.
                    </h1>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default Banner;
