import React, { Component } from 'react';
import Slider from 'react-slick';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { Link, Redirect } from 'react-router-dom';
import Preloader from '../preloader';
import './free-status-list.scss';
import ConfirmPopup from '../card-buy-block/ConfirmPopup';
import DownloadPopup from './download-popup';
import BuyingPopup from '../card-buy-block/BuyingPopup';

const cardsTmplObj = [
  {
    name: '#Z-124',
    category: 'Wedding',
    img: 'assets/images/img-36.png',
    id: 1,
    cardId: 's',
  },
  {
    name: '#Z-124',
    category: 'Kiddish',
    img: 'assets/images/img-37.png',
    id: 2,
    cardId: 'd',
  },
  {
    name: '#Z-124',
    category: 'Kiddish',
    img: 'assets/images/img-34.png',
    id: 3,
    cardId: 'd',
  },
  {
    name: '#Z-124',
    category: 'Sheva Brochos',
    img: 'assets/images/img-35.png',
    id: 4,
    cardId: 'd',
  },
  {
    name: '#Z-124',
    category: 'Wedding',
    img: 'assets/images/img-40.png',
    id: 5,
    cardId: 'd',
  },
  {
    name: '#Z-124',
    category: 'Sheva Brochos',
    img: 'assets/images/img-38.png',
    id: 6,
    cardId: 'd',
  },
  {
    name: '#Z-124',
    category: 'Wedding',
    img: 'assets/images/img-41.png',
    id: 7,
    cardId: 'd',
  },
  {
    name: '#Z-124',
    category: 'Wedding',
    img: 'assets/images/img-39.png',
    id: 8,
    cardId: 'd',
  },
  {
    name: '#Z-124',
    category: 'Sheva Brochos',
    img: 'assets/images/img-33.png',
    id: 9,
    cardId: 's',
  },
];

class FreeStatusList extends Component {
  state = {
    loading: true,
    notFound: false,
    activeCategory: 'all',
    filterArr: [],
    filtersList: [],
    filteredCardsList: [],
    popupActive: false,
    isWhatsApp: false,
    whatsAppPhone: null,
    email: null,
  };

  downloadPopup = React.createRef();

  componentDidMount() {
    this.initList();
  }

  initList = () => {
    this.setState({
      loading: true,
    });

    const { activeCategory } = this.state;
    let updFilterArr = [],
      updFiltersList = [],
      updFilteredCardsList = [];

    updFilteredCardsList = cardsTmplObj.filter((card, i) => {
      const { category, id } = card;

      if (!updFilterArr.includes(category)) {
        const currentClass =
          category === activeCategory
            ? 'btn btn-outline-secondary active-item'
            : 'btn btn-outline-secondary';

        updFilterArr.push(category);
        updFiltersList.push(
          <div key={category + id} className="btn-item">
            <a
              href="/"
              className={currentClass}
              onClick={(e) => this.setActiveCategory(e, category)}
            >
              {category}
            </a>
          </div>
        );
      }

      if (activeCategory === 'all') {
        return card;
      }

      return card.category === activeCategory;
    });

    this.setState({
      filterArr: [...updFilterArr],
      filtersList: [...updFiltersList],
      filteredCardsList: [...updFilteredCardsList],
      loading: false,
    });
  };

  setActiveCategory = (e, category) => {
    if (e) {
      e.preventDefault();
    }

    this.setState(
      {
        activeCategory: category,
      },
      this.initList
    );
  };

  onDownloadClick = (id) => {
    this.setState((state) => ({
      ...state,
      popupActive: true,
    }));
  };

  onCancelDownload = (e) => {
    e.preventDefault();
    this.setState((state) => ({
      ...state,
      popupActive: false,
    }));
  };

  inputChangeHandler = (e) => {
    const name = e.target.name,
      value = e.target.value;

    this.setState({
      [name]: value,
    });
  };

  whatsAppNotificationHandler = () => {
    const { isWhatsApp } = this.state;
    this.setState((state) => ({
      isWhatsApp: !isWhatsApp,
    }));
  };

  whatsAppPhoneNumberHandler = (value) => {
    this.setState((state) => ({
      whatsAppPhone: value,
    }));
  };

  render() {
    const { loading, notFound, activeCategory, filtersList, filteredCardsList } = this.state;

    const sliderSettings = {
      infinite: true,
      autoplay: false,
      arrows: true,
      dots: false,
      centerMode: false,
      variableWidth: true,
    };

    if (notFound) {
      return (
        <>
          <Redirect to="/404" />
        </>
      );
    }

    if (loading) {
      return <Preloader />;
    }

    // init LazyLoad if in viewport
    forceCheck();

    return (
      <section className="custom-motion-list-section free-status-list-section">
        <div className="container">
          <Slider
            {...sliderSettings}
            className="custom-motion-list-nav custom-motion-list-nav-slider free-status-list-nav-slider"
            onInit={() =>
              setTimeout(() => {
                forceCheck();
              }, 100)
            }
          >
            <div className="btn-item">
              <a
                href="/"
                className={
                  activeCategory === 'all'
                    ? 'btn btn-outline-secondary active-item'
                    : 'btn btn-outline-secondary'
                }
                onClick={(e) => this.setActiveCategory(e, 'all')}
              >
                All
              </a>
            </div>
            {filtersList}
            <div className="btn-item for-test">
              <a
                href="/"
                className="btn btn-outline-secondary"
                onClick={(e) => this.setActiveCategory(e, 'all')}
              >
                For test 1
              </a>
            </div>
          </Slider>
          <div className="listing">
            {filteredCardsList.map((card, i) => {
              const { name, category, img, id, cardId } = card;
              return (
                <div key={name + id} className="list-item">
                  <div className="card-preview">
                    <span className="img-holder">
                      <div className="hint">Hover to see preview</div>
                      <LazyLoad throttle={200} height={400} once>
                        <img src={img} alt={name} />
                      </LazyLoad>
                    </span>
                    <span className="list-item-descr">
                      <span className="list-item-name-holder">
                        <h3>{category}</h3>
                        <strong>{name}</strong>
                      </span>
                      <div className="btn-holder">
                        <span
                          role="button"
                          className="btn"
                          onClick={(e) => this.onDownloadClick(id)}
                        >
                          Download
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {this.state.popupActive && (
          <DownloadPopup
            ref={(r) => (this.downloadPopup.current = r)}
            closePopup={this.onCancelDownload}
            isWhatsAppNotificationEnabled={this.state.isWhatsApp}
            whatsAppNotificationHandler={this.whatsAppNotificationHandler}
            whatsAppPhoneNumberHandler={this.whatsAppPhoneNumberHandler}
            whatsAppPhone={this.state.whatsAppPhone}
            email={this.state.email}
            inputChangeHandler={this.inputChangeHandler}
          />
        )}
      </section>
    );
  }
}

export default FreeStatusList;
