import React from 'react';

const IconPencil = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.428"
      height="24.428"
      viewBox="0 0 24.428 24.428"
      className={'icon-pencil'}
    >
      <g id="Group_451" data-name="Group 451" transform="translate(1477.064 -1613.925) rotate(45)">
        <path
          id="Path_90"
          data-name="Path 90"
          d="M-631,2784.216l4.049,5.342,4.048-5.342v-18.78H-631Z"
          transform="translate(741 -592)"
          fill="none"
          stroke="#191919"
          strokeWidth="1"
        />
        <path
          id="Path_91"
          data-name="Path 91"
          d="M-631,2817.112h8.136"
          transform="translate(741 -625.042)"
          fill="none"
          stroke="#191919"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default IconPencil;
