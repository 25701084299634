import React from 'react';

import './preloader-sm.scss';

const PreloaderSm = (props) => {
  return (
    <div className="preloader-sm-holder">
      <div className="loadingio-spinner-spinner-f0i0p9yu4a5">
        <div className="ldio-3593urhrw5j">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default PreloaderSm;
