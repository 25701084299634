const loginUser = (userObj) => ({
  type: 'LOGIN_USER',
  payload: userObj,
});

const logoutUser = () => {
  return {
    type: 'LOGOUT_USER',
  };
};

const activeHeaderPopup = () => {
  return {
    type: 'HEADER_POPUP_ACTIVE',
  };
};

const disableHeaderPopup = () => {
  return {
    type: 'HEADER_POPUP_DISABLE',
  };
};

const toggleHeaderPopup = () => {
  return {
    type: 'HEADER_POPUP_TOGGLE',
  };
};

const setCustomMode = () => {
  return {
    type: 'SET_CUSTOM_MODE',
  };
};

const unsetCustomMode = () => {
  return {
    type: 'UNSET_CUSTOM_MODE',
  };
};

const setActiveCategory = (cat) => {
  return {
    type: 'SET_ACTIVE_CATEGORY',
    payload: cat,
  };
};

const unsetActiveCategory = () => {
  return {
    type: 'UNSET_ACTIVE_CATEGORY',
  };
};

const setActiveSubcategory = (cat) => {
  return {
    type: 'SET_ACTIVE_SUBCATEGORY',
    payload: cat,
  };
};

const unsetActiveSubcategory = () => {
  return {
    type: 'UNSET_ACTIVE_SUBCATEGORY',
  };
};

const setDraftCard = (card) => {
  return {
    type: 'SET_DRAFT_CARD',
    payload: card,
  };
};

const unsetDraftCard = () => {
  return {
    type: 'UNSET_DRAFT_CARD',
  };
};

const setCategories = (list) => {
  return {
    type: 'SET_CATEGORIES_LIST',
    payload: list,
  };
};

const setAllCategories = (list) => {
  return {
    type: 'SET_ALL_CATEGORIES_LIST',
    payload: list,
  };
};

const setVideoAvailability = (isAvailable) => {
  return {
    type: 'SET_VIDEO_AVAILABILITY',
    payload: isAvailable,
  };
};

const setFaqAvailability = (isAvailable) => {
  return {
    type: 'SET_FAQ_AVAILABILITY',
    payload: isAvailable,
  };
};

export {
  loginUser,
  logoutUser,
  activeHeaderPopup,
  disableHeaderPopup,
  toggleHeaderPopup,
  setCustomMode,
  unsetCustomMode,
  setActiveCategory,
  unsetActiveCategory,
  setDraftCard,
  unsetDraftCard,
  setActiveSubcategory,
  unsetActiveSubcategory,
  setFaqAvailability,
  setVideoAvailability,
  setCategories,
  setAllCategories,
};
