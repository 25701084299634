import React, { Component } from 'react';
import ThanksBlock from '../thanks-block';
import PreloaderSm from '../preloader-sm';
import CardService from '../../services/cards-service';

import './contact-form.scss';

class ContactForm extends Component {
  state = {
    loading: false,
    sent: false,
    name: '',
    email: '',
    phone: '',
    order: '',
    comment: '',
    formErrors: {},
    refresh: false,
  };
  cardService = new CardService();

  inputChangeHandler = (e) => {
    const name = e.target.name,
      value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  submitHandler = async (e) => {
    e.preventDefault();

    const { name, email, phone, order, comment } = this.state;

    this.setState({
      loading: true,
    });
    const formData = {
      name,
      email,
      phone,
      order: order || null,
      comment,
    };

    await this.cardService
      .contactMessage(formData)
      .then((res) => {
        // console.log('Sent result', res);
        this.setState({
          sent: true,
          loading: false,
        });
      })
      .catch((err) => {
        // console.log('Sent error', err);
        if (err.errors) {
          this.setState({
            formErrors: this.cardService.formsErrorHandler(err),
            loading: false,
          });
        } else {
          this.setState({
            formErrors: {},
            loading: false,
          });
        }
      });

    this.setState((state) => ({
      refresh: !state.refresh,
    }));
  };

  render() {
    const { loading, sent, name, email, phone, order, comment, formErrors } = this.state;

    if (sent) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return <ThanksBlock />;
    }

    return (
      <div className="inner-page-holder contact-holder">
        <div role="form" className="wpcf7" id="wpcf7-f5-p12-o1" lang="en-US" dir="ltr">
          <div className="screen-reader-response"></div>
          <form
            className="wpcf7-form contact-form"
            noValidate="novalidate"
            onSubmit={this.submitHandler}
          >
            <h2>We Are All Ears!</h2>
            <p>
              Have an order issue or question? Or an idea to share? We will listen and respond
              quickly!
            </p>
            <div className="form-block">
              <div className="input-block input-block-md">
                <label>
                  Name *
                  <span className="your-name">
                    <input
                      type="text"
                      name="name"
                      value={name}
                      size="40"
                      className="form-control"
                      onChange={this.inputChangeHandler}
                    />
                    {formErrors['name'] && formErrors['name'].length ? (
                      <strong className="error">{formErrors['name']}</strong>
                    ) : (
                      ''
                    )}
                  </span>
                </label>
              </div>
              <div className="input-block input-block-md">
                <label>
                  Email *
                  <span className="email">
                    <input
                      type="email"
                      name="email"
                      value={email}
                      size="40"
                      className="form-control"
                      onChange={this.inputChangeHandler}
                    />
                    {formErrors['email'] && formErrors['email'].length ? (
                      <strong className="error">{formErrors['email']}</strong>
                    ) : (
                      ''
                    )}
                  </span>
                </label>
              </div>
              <div className="input-block input-block-md">
                <label>
                  Phone *
                  <span className="phone">
                    <input
                      type="tel"
                      name="phone"
                      value={phone}
                      size="40"
                      className="form-control"
                      onChange={this.inputChangeHandler}
                    />
                    {formErrors['phone'] && formErrors['phone'].length ? (
                      <strong className="error">{formErrors['phone']}</strong>
                    ) : (
                      ''
                    )}
                  </span>
                </label>
              </div>
              <div className="input-block input-block-md">
                <label>
                  Order # (if applicable)
                  <span className="your-order">
                    <input
                      type="text"
                      name="order"
                      value={order}
                      size="40"
                      className="form-control"
                      onChange={this.inputChangeHandler}
                    />
                    {formErrors['order'] && formErrors['order'].length ? (
                      <strong className="error">{formErrors['order']}</strong>
                    ) : (
                      ''
                    )}
                  </span>
                </label>
              </div>
              <div className="input-block">
                <label>
                  Comment / Question / Suggestion
                  <span className="comment">
                    <textarea
                      name="comment"
                      value={comment}
                      cols="40"
                      rows="10"
                      className="form-control"
                      onChange={this.inputChangeHandler}
                    ></textarea>
                    {formErrors['comment'] && formErrors['comment'].length ? (
                      <strong className="error">{formErrors['comment']}</strong>
                    ) : (
                      ''
                    )}
                  </span>
                </label>
              </div>
            </div>
            <div className="btn-holder">
              {loading ? <PreloaderSm /> : ''}
              <input type="submit" value="Submit" className="btn btn-sm btn-fill" />
              <span className="ajax-loader"></span>
            </div>
          </form>
          <div className="thanks-block">
            <h2>Thank You!</h2>
            <div className="wpcf7-response-output wpcf7-display-none"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactForm;
