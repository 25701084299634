import React from 'react';

const SearchPage = () => {
  return (
    <div id="content">
      <div className="title">
        <h1>
          Search Results for: <span>test</span>
        </h1>
      </div>
      <div
        className="post-2230 product type-product status-publish product_cat-uncategorized first instock downloadable virtual purchasable product-type-simple"
        id="post-2230"
      >
        <div className="title">
          <h2>
            <a href="https://ihopetoseeyou.com/product/test/" rel="bookmark">
              Test
            </a>
          </h2>
          <p className="meta-info">
            <a href="https://ihopetoseeyou.com/2020/10/" rel="bookmark">
              <time dateTime="2020-10-13">October 13th, 2020</time>
            </a>
            by <a href="https://ihopetoseeyou.com/author/admin/">admin</a>
          </p>
        </div>
        <div className="content">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat […]
          </p>
        </div>
        <div className="meta">
          <ul>
            <li>Posted in </li>
            <li>
              <a href="https://ihopetoseeyou.com/product/test/#respond">No Comments</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
