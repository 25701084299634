import React, { useCallback, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import './card-buy-block.scss';
import StripeForm from './StripeForm';
import CountryTelData from 'country-telephone-data';

const BuyingPopup = React.forwardRef(
  (
    {
      stripePromise,
      email,
      inputChangeHandler,
      currentUser,
      setOrder,
      loadingStripe,
      setLoadingStripe,
      price,
      cardName,
      closePopup,
      isWhatsAppNotificationEnabled,
      whatsAppNotificationHandler,
      whatsAppPhoneNumberHandler,
      whatsAppPhone,
      stripeError,
    },
    ref
  ) => {
    const codeOptions = CountryTelData.allCountries.map((item) => ({
      value: `+${item.dialCode}`,
      label: `${item.iso2.toUpperCase()} +${item.dialCode}`,
    }));

    return (
      <div
        ref={ref}
        className="popup-open upd-popup-holder buying-popup active"
        data-popup="buying"
      >
        <div
          className="upd-popup-inner"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              closePopup(e);
            }
          }}
        >
          <div className="upd-popup">
            <a href="/" className="close-popup" onClick={(e) => closePopup(e)}>
              Close
            </a>
            <div className="draft-form">
              <div className="info-block">
                <h4>Checkout</h4>
              </div>
              <div className="stripe-holder">
                <div className="input-block">
                  <label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      className="form-control"
                      value={email}
                      onChange={inputChangeHandler}
                    />
                  </label>
                </div>
                <Elements currentUser={currentUser} stripe={stripePromise}>
                  <StripeForm
                    onSuccess={setOrder}
                    loadingStripe={loadingStripe}
                    setLoadingStripe={setLoadingStripe}
                    price={price}
                    cardName={cardName}
                    email={email}
                    stripeError={stripeError}
                  />
                </Elements>
                {/* https://stripe.com/docs/testing */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default BuyingPopup;
