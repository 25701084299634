import React from 'react';
import './card-buy-block.scss';

const ConfirmPopup = React.forwardRef((props, ref) => (
  <div ref={ref} className="popup-open upd-popup-holder confirm-popup" data-popup="confirm">
    <div
      className="upd-popup-inner"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          props.closePopup(e);
        }
      }}
    >
      <div className="upd-popup">
        <a href="/" className="close-popup" onClick={(e) => props.closePopup(e)}>
          Close
        </a>
        <div className="draft-form">
          <div className="info-block">
            <div className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76">
                <g id="Group_731" data-name="Group 731" transform="translate(-725 -366)">
                  <g
                    id="Ellipse_67"
                    data-name="Ellipse 67"
                    transform="translate(728 369)"
                    fill="#fff"
                    stroke="#b88b48"
                    stroke-width="3"
                  >
                    <circle cx="35" cy="35" r="35" stroke="none" />
                    <circle cx="35" cy="35" r="36.5" fill="none" />
                  </g>
                  <ellipse
                    id="Ellipse_68"
                    data-name="Ellipse 68"
                    cx="4.049"
                    cy="4.049"
                    rx="4.049"
                    ry="4.049"
                    transform="translate(758.951 422.067)"
                    fill="#b88b48"
                  />
                  <path
                    id="Path_235"
                    data-name="Path 235"
                    d="M13587.481,5545.028l1.473,32.007h4l1.472-32.007Z"
                    transform="translate(-12828.176 -5163.165)"
                    fill="#b88b48"
                  />
                </g>
              </svg>
            </div>
            <h4>Proof Read</h4>
          </div>
          <p>Please make sure that all fields are correct before checking out.</p>
          <div className="form-row">
            <button onClick={(e) => props.closePopup(e)} className="btn btn-back">
              <i className="fa fa-arrow-left"></i>
              Go back
            </button>
            <button
              type="submit"
              className="btn btn-sm btn-fill popup-opener btn-submit"
              data-popup-opener="buying"
              onClick={props.nextPopup}
            >
              Confirm
              <i className="fa fa-check"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
));

export default ConfirmPopup;
