import React from 'react';
import Banner from '../banner';
import CategoryListSection from '../category-list-section';
import CardSlider from '../card-slider';
import EditorPreview from '../editor-preview';
import CTAUserInterface from '../cta-user-interface';

const Homepage = () => {
  return (
    <>
      <Banner
        bgImg="assets/images/img-02.png"
        bgVideo="https://www.youtube.com/embed/eEzD-Y97ges?mute=1&autoplay=1&controls=0&disablekb=1&modestbranding=1&loop=1&playlist=eEzD-Y97ges&showinfo=0&autohide=1"
      ></Banner>
      <CategoryListSection />
      <CardSlider />
      <EditorPreview />
      {/* Custom motion cta */}
      {/* <CTAUserInterface /> */}
    </>
  );
};

export default Homepage;
