import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { loginUser, logoutUser } from '../../actions';
import CardService from '../../services/cards-service';
import PreloaderSm from '../preloader-sm';

import './register-form.scss';

class RegisterForm extends Component {
  cardService = new CardService();
  state = {
    loading: false,
    password: '',
    email: '',
    password_confirm: '',
    formErrors: { password: '', email: '', password_confirm: '' },
    passwordValid: false,
    emailValid: false,
    password_confirmValid: false,
    formValid: false,
    loggedIn: false,
  };

  componentDidMount() {
    this.checkUserLogin();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser !== this.props.currentUser) {
      this.checkUserLogin();
    }
  }

  checkUserLogin = () => {
    if (this.props.currentUser) {
      this.setState({
        loggedIn: true,
      });
    }
  };

  inputChangeHandler = (e) => {
    const name = e.target.name,
      value = e.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        // this.validateField(name, value);
      }
    );
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      loading: true,
    });

    const {
      email,
      password,
      password_confirm,
      // formErrors
      // formValid
    } = this.state;

    // this.initFilter();

    // User Test
    // user@test.com
    // userTest
    // token MQ.x0mXY3okFmmmO5eamTFvMI0si11wLsMYRiVrVbWCsxFX_LlVev_L-swAi12H

    const userObj = {
      email,
      password,
      password_confirm: password_confirm,
    };
    await this.cardService
      .userRegister(userObj)
      .then((res) => {
        localStorage.setItem('token', res.token);
        this.props.loginUser({
          email: email,
          token: res.token,
        });
        return res;
      })
      .then((res) => {
        this.setState({
          loggedIn: true,
          loading: false,
        });
      })
      .catch((err) => {
        // console.log('Register error', err);
        if (err.errors) {
          this.setState({
            formErrors: this.cardService.formsErrorHandler(err),
            loading: false,
          });
        } else {
          this.setState({
            formErrors: {},
            loading: false,
          });
        }
      });
  };

  render() {
    const {
      loading,
      password,
      email,
      password_confirm,
      formErrors,
      passwordValid,
      emailValid,
      password_confirmValid,
      loggedIn,
    } = this.state;

    if (loggedIn) {
      return <Redirect to="/account" />;
    }

    return (
      <div className="inner-page-holder login-holder">
        <form className="register register-form" onSubmit={this.handleSubmit}>
          <div className="form-row form-row-wide">
            <label htmlFor="reg_email">
              Email address / username &nbsp;<span className="required">*</span>
            </label>
            <input
              type="email"
              className="input-text"
              name="email"
              id="reg_email"
              value={email}
              autoComplete="username"
              onChange={this.inputChangeHandler}
            />
            {!emailValid && formErrors['email'] && formErrors['email'].length ? (
              <strong className="error">{formErrors['email']}</strong>
            ) : (
              ''
            )}
          </div>
          <div className="form-row form-row-wide">
            <label htmlFor="reg_password">Select Password</label>
            <input
              type="password"
              className="input-text"
              name="password"
              id="reg_password"
              autoComplete="new-password"
              value={password}
              onChange={this.inputChangeHandler}
            />
            {!passwordValid && formErrors['password'] && formErrors['password'].length ? (
              <strong className="error">{formErrors['password']}</strong>
            ) : (
              ''
            )}
          </div>
          <div className="form-row form-row-wide">
            <label htmlFor="repeat_reg_password">Repeat Password</label>
            <input
              type="password"
              className="input-text"
              name="password_confirm"
              id="repeat_reg_password"
              autoComplete="new-password"
              value={password_confirm}
              onChange={this.inputChangeHandler}
            />
            {!password_confirmValid &&
            formErrors['password_confirm'] &&
            formErrors['password_confirm'].length ? (
              <strong className="error">{formErrors['password_confirm']}</strong>
            ) : (
              ''
            )}
          </div>
          <div className="woocommerce-privacy-policy-text">
            <p>
              Your personal data will be used to support your experience throughout this website, to
              manage access to your account, and for other purposes described in our{' '}
              <Link to="/privacy-policy" target="_blank" rel="noreferrer">
                privacy policy
              </Link>
              .
            </p>
          </div>
          <div className="btn-block">
            {loading ? <PreloaderSm /> : ''}
            <button type="submit" className="button" name="register">
              Register
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = {
  loginUser,
  logoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
