import React from 'react';

const DefaultPageTemplate = ({ title, inner = ' ' }) => {
  return (
    <>
      <section className="detail-section">
        <div className="container container-md">
          {title ? (
            <div className="page-heading">
              <h1 className="block-header">{title}</h1>
            </div>
          ) : (
            ''
          )}
          {inner}
        </div>
      </section>
    </>
  );
};

export default DefaultPageTemplate;
