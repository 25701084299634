import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LazyLoad, { forceCheck } from 'react-lazyload';
import CardService from '../../services/cards-service';
import './category-list-section.scss';

class CategoryListSection extends Component {
  cardService = new CardService();
  state = {
    categoryList: null,
  };

  componentDidMount() {
    this.getCategories();
  }

  getCategories = async () => {
    // let newCategoryList = [...categoryListTmpl];
    await this.cardService
      .getCategoryListHome()
      .then((res) => {
        let newCategoryList = [];
        // console.log('categoriesList', res)
        res.forEach((item, i) => {
          newCategoryList.push({
            title: item.name,
            slug: item.parent ? `${item.parent.slug}?sub=${item.slug}` : item.slug,
            img: item.imageUrl,
            id: item.id,
          });
        });

        this.setState({
          categoryList: [...newCategoryList],
        });
      })
      .catch((err) => {
        console.log('Category section error', err);
      });

    // categoriesList.forEach((item, i) => {
    //   let imgNum = 3 + i;

    //   if (imgNum > 9) {
    //     imgNum = 3 + Math.floor(i % 7);
    //   }

    //   newCategoryList.push({
    //     title: item.name,
    //     slug: item.slug,
    //     img: `/assets/images/img-0${imgNum}.png`,
    //     id: item.id
    //   })
    // })

    // this.setState({
    //   categoryList: [...newCategoryList]
    // })
  };

  render() {
    const { categoryList } = this.state;
    // console.log(categoryList)

    if (!categoryList) {
      return '';
    }

    // init LazyLoad if in viewport
    forceCheck();

    return (
      <section className="categories-list-section">
        <div className="container">
          <div className="section-heading">
            <h2 className="section-title">
              What <span className="marked-text">Simcha</span> are you celebrating?
            </h2>
            <div className="heading-note">
              <p>Choose the category of the Simcha your making to get started</p>
            </div>
          </div>
          <div className="categories-list">
            {categoryList.map((item) => (
              <Link to={`/category-of-card/${item.slug}`} className="category-item" key={item.id}>
                <span className="img-holder">
                  {item.img ? (
                    <LazyLoad throttle={200} height={95} once>
                      <img src={item.img} alt={item.title} />
                    </LazyLoad>
                  ) : (
                    ''
                  )}
                  {/* <LazyLoad throttle={200} height={95} once>
                    <img src={item.img} alt={item.title}/>
                  </LazyLoad> */}
                  {/* <img src={item.img} alt={item.title}/> */}
                </span>
                <span className="category-item-title">{item.title}</span>
              </Link>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default CategoryListSection;
