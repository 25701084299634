import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { loginUser, logoutUser } from '../../actions';
import CardService from '../../services/cards-service';
import PreloaderSm from '../preloader-sm';

import './login-form.scss';

class LoginForm extends Component {
  cardService = new CardService();
  state = {
    loading: false,
    email: '',
    password: '',
    formErrors: {},
    loginFalse: false,
    loggedIn: false,
  };

  componentDidMount() {
    this.checkUserLogin();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser !== this.props.currentUser) {
      this.checkUserLogin();
    }
  }

  checkUserLogin = () => {
    if (this.props.currentUser) {
      this.setState({
        loggedIn: true,
      });
    }
  };

  inputChangeHandler = (e) => {
    const name = e.target.name,
      value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    const { email, password } = this.state;
    // if (!email && !password) {
    //   return;
    // }

    // User Test
    // user@test.com
    // userTest
    // token MQ.x0mXY3okFmmmO5eamTFvMI0si11wLsMYRiVrVbWCsxFX_LlVev_L-swAi12H

    // User5 Test
    // user5@test.com
    // user5Test
    // token MQ.x0mXY3okFmmmO5eamTFvMI0si11wLsMYRiVrVbWCsxFX_LlVev_L-swAi12H

    const userObj = {
      email: email,
      password: password,
    };
    await this.cardService
      .userLogin(userObj)
      .then((res) => {
        this.setState(
          {
            loggedIn: true,
            loading: false,
          },
          () => {
            localStorage.setItem('token', res.token);
            this.props.loginUser({
              email: email,
              token: res.token,
            });
          }
        );
        return res;
      })
      .catch(async (err) => {
        if (err.errors) {
          let newFormErrors = {};
          let fieldNames = [];
          err.errors.map((item) => {
            if (!fieldNames.includes(item.field)) {
              newFormErrors[item.field] = item.message;
              fieldNames.push(item.field);
            }
            return item;
          });
          this.setState({
            formErrors: newFormErrors,
            loginFalse: false,
            loading: false,
          });
        } else {
          err
            .text()
            .then((text) => {
              this.setState({
                formErrors: {},
                loginFalse: text,
                loading: false,
              });
            })
            .catch((err) => {
              this.setState({
                formErrors: {},
                loading: false,
              });
            });
        }
      });
  };

  render() {
    const { loading, email, password, formErrors, loggedIn, loginFalse } = this.state;

    if (loggedIn) {
      return <Redirect to="/account" />;
    }

    return (
      <div className="inner-page-holder login-holder">
        <h3>Welcome Back!</h3>
        <p>Please provide your login information</p>
        <form name="loginform" id="loginform" className="login-form" onSubmit={this.handleSubmit}>
          <div className="form-row login-username">
            <label htmlFor="user_login">Email Address:</label>
            <input
              type="email"
              name="email"
              id="user_login"
              className="input"
              value={email}
              onChange={this.inputChangeHandler}
            />
            {formErrors['email'] && formErrors['email'].length ? (
              <strong className="error">{formErrors['email']}</strong>
            ) : (
              ''
            )}
          </div>
          <div className="form-row login-password">
            <label htmlFor="user_pass">Password:</label>
            <input
              type="password"
              name="password"
              id="user_pass"
              className="input"
              autoComplete="password"
              value={password}
              onChange={this.inputChangeHandler}
            />
            {formErrors['password'] && formErrors['password'].length ? (
              <strong className="error">{formErrors['password']}</strong>
            ) : (
              ''
            )}
          </div>
          {loginFalse ? <strong className="error error-result">{loginFalse}</strong> : ''}
          <div className="note-holder">
            <Link to="/lost-password">Forgot Password?</Link>
          </div>
          <div className="grid">
            <div>
              <b>Not registered?</b>
            </div>
          </div>
          <div className="grid" style={{ width: '50%' }}>
            <Link to="/registration" className="link" style={{ color: '#e0c5ad' }}>
              Register Now
              <p className="horizontal-line-login">
                <span></span>
              </p>
            </Link>
          </div>
          <div className="login-submit" style={{ marginTop: '-80px' }}>
            {loading ? <PreloaderSm /> : ''}
            <input
              type="submit"
              name="wp-submit"
              id="wp-submit"
              className="button button-primary"
              value="Login"
            />
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = {
  loginUser,
  logoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
