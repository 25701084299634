import React from 'react';

function CardInputField({
  item,
  index,
  changeHandler,
  toggleInputVal,
  keyboard,
  lang,
  toggleKeyboardView,
}) {
  const {
    text,
    text_alignment,
    // direction,
    // fieldHidden,
    defaultText,
    isHidden,
  } = item;

  let inputClass = 'input-holder';
  // const fieldHidden = isHidden[index];
  const fieldHidden = +isHidden;
  if (fieldHidden && fieldHidden !== 0) {
    inputClass += ' hidden-field';
  }

  let direction = lang === 'hebrew' ? 'rtl' : 'ltr';

  return (
    <div className={inputClass}>
      <input
        className="content-field keyboardInput"
        type="text"
        dir="auto"
        // style={{"direction": direction}}
        data-alignment={text_alignment}
        data-layout="hebrew"
        data-fontfamily="https://carddev.itcraftlab.com/wp-content/plugins/ihopetoseeyou-card/fonts-for-card/FbTkuma-Regular.ttf|||fbtkuma"
        data-defaultvalue={text}
        value={text}
        onChange={(e) => changeHandler(e.target.value, index)}
        name={`input-${index}`}
      />
      <img
        src="/assets/images/icon-20.png"
        alt="Display virtual keyboard interface"
        className="keyboardInputInitiator"
        title="Display virtual keyboard interface"
        onClick={(e) => toggleKeyboardView(e, index, keyboard)}
      />
      <a
        href="/"
        className={text === defaultText ? 'reset-link clear-state' : 'reset-link reset-state'}
        onClick={(e) => toggleInputVal(e, index, defaultText)}
      >
        {text === defaultText ? 'Clear field' : 'Reset'}
      </a>
    </div>
  );
}

export default CardInputField;
