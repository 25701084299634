import React from 'react';

import './preloader.scss';

const Preloader = (props) => {
  return (
    <div className="preloader-holder">
      <img width="250" height="250" src="/assets/images/logo-loading.gif" alt="loading..." />
    </div>
  );
};

export default Preloader;
