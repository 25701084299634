import React from 'react';

import './thanks-block.scss';

const ThanksBlock = () => {
  return (
    <div className="inner-page-holder contact-holder thanks-block">
      <div className="thanks-block">
        <h2>Thank You!</h2>
        <p>Your request has been received, we will get back to you shortly.</p>
      </div>
    </div>
  );
};

export default ThanksBlock;
