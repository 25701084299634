import React from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import '../category-list/category-list.scss';

function CategoryCard({ card }) {
  const { id, name, categories, price, cardDetails, labels } = card;

  return (
    <Link to={`/card/${id}`} className="card-preview">
      {!!labels?.length && (
        <div className="card-preview-tags">
          {labels.map((label) => (
            <span key={label.id} className={`btn ${label.id === 2 ? 'btn-danger' : 'btn-fill'}`}>
              {label.name}
            </span>
          ))}
        </div>
      )}
      <span className="img-holder">
        <LazyLoad throttle={200} height={326} once>
          <img src={cardDetails ? cardDetails.previewUrl || '' : ''} alt={name} />
        </LazyLoad>
      </span>
      <span className="list-item-descr">
        <span className="list-item-name-holder">
          {categories && categories.length > 0 ? <h3>{categories[0].name}</h3> : ''}
          <strong>{name}</strong>
        </span>
        <span className="price">
          <span className="woocommerce-Price-amount amount">
            {price ? (
              <>
                <span className="woocommerce-Price-currencySymbol">&#36;</span>
                {price}
              </>
            ) : (
              'Free'
            )}
          </span>
        </span>
      </span>
    </Link>
  );
}

export default CategoryCard;
