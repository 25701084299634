import React, { Component } from 'react';
import CustomCardItem from '../custom-card-item';
import { connect } from 'react-redux';
import { setCustomMode, unsetCustomMode } from '../../actions';

class CustomCardItemPage extends Component {
  componentWillUnmount() {
    const { unsetCustomMode } = this.props;
    unsetCustomMode();
  }

  render() {
    return <CustomCardItem card={this.props.match.params.customCard || ''} />;
  }
}

const mapStateToProps = (state) => {
  return {
    isCustomMode: state.isCustomMode,
  };
};

const mapDispatchToProps = {
  setCustomMode,
  unsetCustomMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomCardItemPage);
