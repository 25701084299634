import React, { Component } from 'react';
import CardService from '../../services/cards-service';
import WhatsAppWidget from 'react-whatsapp-chat-widget';
import 'react-whatsapp-chat-widget/index.css';
import './whatsapp-chat.scss';

class ChatWidget extends Component {
  cardService = new CardService();
  state = {
    defaultSettings: {
      phoneNo: '17186125052',
      position: 'right',
      widgetWidth: '300px',
      widgetWidthMobile: '260px',
      autoOpen: false,
      autoOpenTimer: 5000,
      messageBox: false,
      messageBoxTxt: '',
      iconSize: '54',
      iconColor: '#090509',
      iconBgColor: '#deb372',
      headerIcon: '/assets/images/I-hope-to-see-you-2-upd-2.png',
      headerIconColor: '#090509',
      headerTxtColor: '#090509',
      headerBgColor: '#deb372',
      headerTitle: 'Ihopetoseeyou.com',
      headerCaption: 'Online',
      bodyBgColor: '#bbb',
      chatPersonName: 'Ihopetoseeyou.com',
      chatMessage: (
        <>
          Hi there 👋 <br />
          <br /> How can we help you?
        </>
      ),
      footerBgColor: '#999',
      btnBgColor: '#deb372',
      btnTxtColor: '#090509',
    },
    updSettings: null,
  };

  componentDidMount() {
    this.getChatySetting();
  }

  getChatySetting = async () => {
    await this.cardService
      .getChatySettings()
      .then((settings) => {
        this.setState({
          updSettings: { ...this.keysToCamel(settings) },
        });
      })
      .catch((err) => {
        console.log('Error', err);
      });
  };

  toCamel = (str) => {
    return str.replace(/([-_][a-z])/gi, ($1) => {
      return $1.toUpperCase().replace('-', '').replace('_', '');
    });
  };

  keysToCamel = function (obj) {
    const newObj = {};

    Object.keys(obj).forEach((k) => {
      let newName = this.toCamel(k);

      switch (newName) {
        case 'icon': {
          newName = 'headerIcon';
          break;
        }
        case 'width': {
          newName = 'widgetWidth';
          break;
        }
        case 'widthMobile': {
          newName = 'widgetWidthMobile';
          break;
        }
        case 'phoneNumber': {
          newName = 'phoneNo';
          break;
        }
        case 'msgBoxTxt': {
          newName = 'messageBoxTxt';
          break;
        }
        default:
          break;
      }

      newObj[newName] = obj[k];
    });

    return newObj;
  };

  render() {
    const { defaultSettings, updSettings } = this.state;
    const resultSettings = { ...defaultSettings, ...updSettings };

    // console.log('RESULT OBJ', resultSettings);

    return (
      <WhatsAppWidget
        {...resultSettings}
        // phoneNo="17186125052"
        // position="right"
        // widgetWidth="300px"
        // widgetWidthMobile="260px"
        // autoOpen={false}
        // autoOpenTimer={5000}
        // messageBox={false}
        // // messageBoxTxt={'Hi there! How can we help you?'}
        // messageBoxTxt={''}
        // iconSize="54"
        // iconColor="#090509"
        // iconBgColor="#deb372"
        // headerIcon="/assets/images/I-hope-to-see-you-2-upd-2.png"
        // headerIconColor="#090509"
        // headerTxtColor="#090509"
        // headerBgColor="#deb372"
        // headerTitle="Ihopetoseeyou.com"
        // headerCaption="Online"
        // bodyBgColor="#bbb"
        // chatPersonName="Ihopetoseeyou.com"
        // // chatPersonName="&nbsp;"
        // chatMessage={<>Hi there 👋 <br /><br /> How can we help you?</>}
        // // chatMessage={<></>}
        // footerBgColor="#999"
        // btnBgColor="#deb372"
        // btnTxtColor="#090509"
      />
    );
  }
}

export default ChatWidget;
