import React, { Component } from 'react';
import Cropper from 'react-cropper';
import './card-add-image.scss';

import 'cropperjs/dist/cropper.css';
import IconPencil from './icon-pencil';
import IconClose from './icon-close';
import IconUpload from './icon-upload';

class CardAddImage extends Component {
  state = {
    showCropPopup: false,
    imgPath: '',
    error: null,
    showUploadPopup: false,
    isFile: false,
  };
  file = React.createRef();
  cropper = React.createRef();

  cropPopupOpen = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState((state) => ({
      showCropPopup: true,
      showUploadPopup: false,
      error: null,
    }));
  };

  cropPopupClose = () => {
    this.setState((state) => ({
      showCropPopup: false,
      error: null,
      imgPath: '',
    }));
    this.file.current.value = '';
  };

  uploadPopupOpen = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState((state) => ({
      showUploadPopup: true,
      error: null,
    }));
  };

  uploadPopupClose = () => {
    this.setState((state) => ({
      showUploadPopup: false,
      error: null,
    }));
  };

  onFileUpload = (e) => {
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const maxSize = 20971520;

    if (files && files[0].size > maxSize) {
      this.setState((state) => ({
        ...state,
        error: `The image may not be greater than ${maxSize / 1024 / 1024} Mb`,
        isFile: false,
      }));

      return;
    } else {
      this.setState((state) => ({
        ...state,
        error: null,
        isFile: true,
      }));
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      this.setState({
        imgPath: reader.result,
      });
    };

    reader.readAsDataURL(files[0]);
  };

  onResult = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { setAddImg } = this.props;

    if (this.props.isLogo) {
      setAddImg(
        this.cropper.current.getCroppedCanvas().toDataURL(),
        this.cropper.current.getCroppedCanvas().width / 2,
        this.cropper.current.getCroppedCanvas().height / 2
      );
    } else {
      setAddImg(this.cropper.current.getCroppedCanvas().toDataURL());
    }

    this.setState({
      showCropPopup: false,
    });
  };

  removeImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { setAddImg } = this.props;
    setAddImg(null);
    this.setState((state) => ({
      imgPath: '',
    }));
    this.file.current.value = '';
    this.cropper.current.clear();
    this.cropper.current.reset();
  };

  render() {
    const { addImg, useDefaultImg, isLogo } = this.props;

    const { showCropPopup, showUploadPopup, imgPath, error } = this.state;

    // console.log(imgPath, this.file.current?.value)

    return (
      <div className="image-holder popup-holder">
        <button className="btn-add popup-opener" onClick={this.uploadPopupOpen}>
          {isLogo ? <IconUpload /> : <IconPencil />}
          {isLogo ? 'upload logo' : 'UPLOAD IMAGE'}
          {!useDefaultImg && (
            <span
              className={'btn-add-remove'}
              onClick={this.removeImage}
              style={{
                backgroundSize: 'cover',
                backgroundPosition: '50% 50%',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${isLogo ? addImg.image : addImg.url})`,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.77"
                height="16.77"
                viewBox="0 0 16.77 16.77"
              >
                <g id="Group_700" data-name="Group 700" transform="translate(-941.921 -103.781)">
                  <g id="Group_53" data-name="Group 53" transform="translate(943.335 105.195)">
                    <path
                      id="Path_17"
                      data-name="Path 17"
                      d="M3452.082,691l-13.942,13.942"
                      transform="translate(-3438.14 -691)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_18"
                      data-name="Path 18"
                      d="M13.942,0,0,13.942"
                      transform="translate(13.942) rotate(90)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                  </g>
                </g>
              </svg>
            </span>
          )}
        </button>
        <input type="text" className="hide-input cropped-image" name="cropped-image" />

        <div className={showUploadPopup ? 'image-upload-popup active' : 'image-upload-popup'}>
          <div className="popup-content">
            <div className="popup-close" onClick={this.uploadPopupClose}>
              <IconClose />
            </div>
            <h2>Upload Your Image</h2>
            <p>
              Note! Images should be a <b>jpeg</b> or <b>png</b> file. To convert your <b>PDF</b> to
              jpeg{' '}
              <a href="https://www.freepdfconvert.com/pdf-to-jpg" target="_blank">
                click here.
              </a>
            </p>
            <div className="upload-area">
              <button className="btn-add popup-opener" onClick={this.cropPopupOpen}>
                UPLOAD IMAGE
                <IconPencil />
              </button>
            </div>
          </div>
        </div>
        <div className={showCropPopup ? 'image-upload-popup active second' : 'image-upload-popup'}>
          <div className="popup-content">
            <div className="popup-close" onClick={this.cropPopupClose}>
              <IconClose />
            </div>
            <h2>Crop Your Image</h2>
            <div className="croppie-holder">
              <span className="jcf-file">
                <span className="jcf-fake-input">No file chosen</span>
                <span className="jcf-upload-button">
                  <span className="jcf-button-content">Upload new image</span>
                </span>
                <input
                  name="innerimage"
                  type="file"
                  className="upload jcf-real-element"
                  accept=".jpg, .jpeg, .png"
                  style={{ position: 'absolute', opacity: '0' }}
                  ref={(r) => (this.file.current = r)}
                  onChange={this.onFileUpload}
                />
              </span>
              {error && <div className="error">{error}</div>}
              <div className="croppie-msg">
                {imgPath ? (
                  <Cropper
                    style={{ height: 450, width: '100%', margin: '0 auto' }}
                    zoomTo={0.5}
                    initialAspectRatio={1}
                    src={imgPath}
                    viewMode={2}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    dragMode={'move'}
                    aspectRatio={isLogo ? 'auto' : addImg.width / addImg.height}
                    onInitialized={(instance) => {
                      this.cropper.current = instance;
                    }}
                    guides={false}
                  />
                ) : (
                  <div className="loader"></div>
                )}
              </div>
              <input
                type="button"
                className="apply btn btn-sm"
                value="ADD IMAGE"
                disabled={!this.state.isFile}
                onClick={this.onResult}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardAddImage;
