import React from 'react';

const IconSearch = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.885"
      height="15.393"
      viewBox="0 0 15.885 15.393"
    >
      <g id="Group_897" dataname="Group 897" transform="translate(-1411.344 -102.834)">
        <g
          id="Ellipse_69"
          dataname="Ellipse 69"
          transform="translate(1411.344 102.834)"
          fill="none"
          stroke="#deb372"
          strokeLinecap="round"
          strokeWidth="1.5"
        >
          <circle cx="6.351" cy="6.351" r="6.351" stroke="none" />
          <circle cx="6.351" cy="6.351" r="5.601" fill="none" />
        </g>
        <path
          id="Path_254"
          dataname="Path 254"
          d="M1938,699.658l4.109,4.109"
          transform="translate(-515.937 -586.6)"
          fill="none"
          stroke="#deb372"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};

export default IconSearch;
