import React from 'react';

const IconClose = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.77" height="16.77" viewBox="0 0 16.77 16.77">
      <g id="Group_700" data-name="Group 700" transform="translate(-941.921 -103.781)">
        <g id="Group_53" data-name="Group 53" transform="translate(943.335 105.195)">
          <path
            id="Path_17"
            data-name="Path 17"
            d="M3452.082,691l-13.942,13.942"
            transform="translate(-3438.14 -691)"
            fill="none"
            stroke="#b88b48"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <path
            id="Path_18"
            data-name="Path 18"
            d="M13.942,0,0,13.942"
            transform="translate(13.942) rotate(90)"
            fill="none"
            stroke="#b88b48"
            strokeLinecap="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconClose;
