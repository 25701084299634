import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import LazyLoad, { forceCheck } from 'react-lazyload';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './best-sellers.scss';

const BestSellers = ({ cardList }) => {
  if (!cardList || !cardList.length) {
    return '';
  }

  const sliderSettings = {
    infinite: false,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          variableWidth: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  forceCheck();

  return (
    <div className="best-sellers-holder">
      <div className="block-heading">
        <h3 className="heading">
          <span>Best Sellers</span>
        </h3>
      </div>
      <div className="">
        <Slider {...sliderSettings} className="best-seller-slider">
          {cardList.map((item) => (
            <div key={item.id}>
              <Link to={`/card/${item.id}`} className="card-preview">
                <span className="img-holder">
                  <LazyLoad throttle={200} height={264} once>
                    <img src={item.cardDetails.previewUrl} alt={item.name} />
                  </LazyLoad>
                </span>
                <span className="list-item-descr">
                  <span className="list-item-name-holder">
                    <h3>{item.cardCategory}</h3>
                    {item.categories && item.categories.length > 0 ? (
                      <h3>{item.categories[0].name}</h3>
                    ) : (
                      ''
                    )}
                    <strong>{item.name}</strong>
                  </span>
                  <span className="price">
                    <span className="woocommerce-Price-amount amount">
                      <span className="woocommerce-Price-currencySymbol">&#36;</span>
                      {item.price}
                    </span>
                  </span>
                </span>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default BestSellers;
