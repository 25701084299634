import React from 'react';

const IconUpload = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.758"
      height="14.861"
      viewBox="0 0 11.758 14.861"
      className={'icon-upload'}
    >
      <g data-name="Group 903" transform="translate(-770.085 -462.071)">
        <path
          id="Path_255"
          data-name="Path 255"
          d="M10807.465,7626.622V7615.4"
          transform="translate(-10031.65 -7152.466)"
          fill="none"
          stroke="#191919"
          strokeWidth="1"
        />
        <path
          id="Path_256"
          data-name="Path 256"
          d="M10792.877,7715.007l5.376-5.377,5.376,5.377"
          transform="translate(-10022.438 -7246.852)"
          fill="none"
          stroke="#191919"
          strokeWidth="1"
        />
        <path
          id="Path_257"
          data-name="Path 257"
          d="M10797.705,7639.909h11.4"
          transform="translate(-10027.267 -7163.476)"
          fill="none"
          stroke="#191919"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default IconUpload;
