import React, { Component } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import { activeHeaderPopup, disableHeaderPopup, toggleHeaderPopup } from '../../actions';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './video-popup.scss';
import video from './hd0992.mov';
import CardService from '../../services/cards-service';

class VideoPopup extends Component {
  cardService = new CardService();

  state = {
    video: [],
    activeSlide: 0,
    isHelpful: false,
    isNotHelpful: false,
    faq: [],
  };

  getVideo = async () => {
    await this.cardService
      .getVideo()
      .then((res) => {
        this.setState({
          video: res,
        });
      })
      .catch((err) => {
        console.log('Video error', err);
      });
  };

  getFAQ = async () => {
    await this.cardService
      .getFAQ()
      .then((res) => {
        this.setState((state) => ({
          ...state,
          faq: res,
        }));
      })
      .catch((err) => {
        console.log('Faq error', err);
      });
  };

  componentDidMount() {
    this.getVideo();
    this.getFAQ();
    if (window.location.hash === '#openFaqPopup') {
      this.props.activeHeaderPopup();
    }
  }

  popupToggle = (e) => {
    e.preventDefault();

    this.props.toggleHeaderPopup();
  };

  closePopup = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      isHelpful: false,
      isNotHelpful: false,
    });
    this.props.disableHeaderPopup();
  };

  setHelpful = (e) => {
    e.preventDefault();
    this.setState({
      isHelpful: true,
      isNotHelpful: false,
    });
  };

  setNotHelpful = (e) => {
    e.preventDefault();
    this.setState({
      isHelpful: false,
      isNotHelpful: true,
    });
  };

  playVideo = (e) => {
    e.preventDefault();

    const btn = e.currentTarget;
    const overblock = btn.parentElement;
    const holder = overblock.parentElement;
    const video = holder.querySelector('video');
    const activeClass = 'video-playing';

    overblock.style.cssText = `
              opacity: 0;
              visibility: hidden;
            `;
    holder.classList.add(activeClass);
    video.play();
  };

  onAccordionClick = (e) => {
    e.preventDefault();
    e.target.closest('.accordion-item').classList.toggle('opened');
  };

  slickGoTo = (e, i) => {
    e.preventDefault();
    this.slider.slickGoTo(i);
    this.setState((state) => ({
      ...state,
      activeSlide: i,
    }));
  };

  onChangeSlide = (i) => {
    this.setState((state) => ({
      ...state,
      activeSlide: i,
    }));
  };

  render() {
    const { popupActive, videoEnabled, faqEnabled } = this.props;

    const sliderSettings = {
      fade: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      beforeChange: () => {
        const videos = document.querySelectorAll('video');
        videos.forEach((video) => {
          video.pause();
        });
      },
    };

    return (
      <div className={popupActive ? 'upd-popup-holder popup-open' : 'upd-popup-holder'} id="popup1">
        <div
          className="upd-popup-inner"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              this.closePopup(e);
            }
          }}
        >
          {popupActive ? (
            <div>
              <div className="upd-popup">
                <a href="/" className="close-popup" onClick={(e) => this.closePopup(e)}>
                  Close
                </a>
                {videoEnabled && (
                  <div>
                    <Slider
                      ref={(slider) => (this.slider = slider)}
                      {...sliderSettings}
                      className="popup-slider"
                      afterChange={(i) => this.onChangeSlide(i)}
                    >
                      {this.state.video.map((item) => (
                        <div className="popup-slide" key={item.id}>
                          <div className="video-holder">
                            <div className="custom-play-btn-holder">
                              <video width="400" height="300" controls="controls">
                                <source src={item.videoUrl} type="video/mp4" />
                              </video>
                              <div className="over-block">
                                <h4>{item.title}</h4>
                                <a
                                  href="/"
                                  className="video-play-btn"
                                  onClick={(e) => this.playVideo(e)}
                                >
                                  Play
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                    <div className="popup-slider-descr">
                      <ul className="descr-link-list">
                        {this.state.video.map((item, i) => (
                          <li
                            key={item.id}
                            className={this.state.activeSlide === i ? 'active' : ''}
                          >
                            <a href="/" onClick={(e) => this.slickGoTo(e, i)}>
                              {item.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                {faqEnabled && (
                  <div>
                    <div className="upd-popup-section">
                      <h2>Frequently Asked Questions</h2>
                      <div className="accordion">
                        {this.state.faq.map((item) => (
                          <div
                            className="accordion-item"
                            key={item.id}
                            onClick={(e) => this.onAccordionClick(e)}
                          >
                            <div className="summary">{item.title}</div>
                            <div className="text">{item.description}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="upd-popup-section">
                      <div className="df">
                        <div>
                          <b>Was the answer you were looking for helpful?</b>
                        </div>
                        <button className="btn" onClick={(e) => this.setHelpful(e)}>
                          Yes
                        </button>
                        <button className="btn" onClick={(e) => this.setNotHelpful(e)}>
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {(this.state.isNotHelpful || this.state.isHelpful) && (
                <div className="faq-popup-result">
                  {this.state.isNotHelpful && (
                    <div>
                      <div>That's a bommer :( No worries, send us a WhatsApp:</div>
                      <a
                        className={'btn wa-btn'}
                        href="https://wa.me/17186125052"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-whatsapp"></i>
                        WhatsApp Us
                      </a>
                    </div>
                  )}
                  {this.state.isHelpful && <div>Amazing. Thanks for you feedback!</div>}
                </div>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    popupActive: state.headerPopupActive,
    videoEnabled: state.videoEnabled,
    faqEnabled: state.faqEnabled,
  };
};

const mapDispatchToProps = {
  activeHeaderPopup,
  disableHeaderPopup,
  toggleHeaderPopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoPopup);
