import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  toggleHeaderPopup,
  logoutUser,
  setDraftCard,
  unsetDraftCard,
  setFaqAvailability,
  setVideoAvailability,
  setCategories,
  setAllCategories,
} from '../../actions';
import CardService from '../../services/cards-service';

import './header.scss';
import { SearchForm } from '../search-form/search-form';

class Header extends Component {
  cardService = new CardService();
  state = {
    menuActive: false,
    // featuredCategories: {
    //   simchas: [],
    //   seasons: []
    // }
    featuredCategories: [],
    videoStatusButton: null,
    searchActive: false,
  };
  stickyBlock = React.createRef();

  componentDidMount() {
    this.getMenu();
    window.addEventListener('scroll', this.initStickyBlock, false);
    window.addEventListener('resize', this.initStickyBlock, false);
    document.addEventListener('click', this.onMenuOutsideClick);

    this.getDraft();
    this.getVideoAvailability();
    this.getFaqAvailability();
    this.getVideoStatusButton();
  }

  componentDidUpdate(prevProps) {
    // close burger menu on location change
    const locationChanged = this.props.location !== prevProps.location;

    if (locationChanged) {
      this.setState(
        (state) => ({
          menuActive: false,
        }),
        () => {
          document.body.classList.remove('menu-active');
        }
      );
    }

    if (this.props.isCustomMode !== prevProps.isCustomMode) {
      window.removeEventListener('scroll', this.initStickyBlock, false);
      window.addEventListener('scroll', this.initStickyBlock, false);
      window.removeEventListener('resize', this.initStickyBlock, false);
      window.addEventListener('resize', this.initStickyBlock, false);
    }

    if (this.props.draftCard !== prevProps.draftCard) {
      this.props.history.push(`/card/${this.props.draftCard.card_id}`);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.initStickyBlock, false);
    window.removeEventListener('resize', this.initStickyBlock, false);
    document.removeEventListener('click', this.onMenuOutsideClick);
  }

  getVideoAvailability = async () => {
    await this.cardService
      .getVideoAvailability()
      .then((res) => {
        const { video_how_it_works_enabled } = res;

        this.props.setVideoAvailability(video_how_it_works_enabled);
      })
      .catch((err) => {
        console.log('Video error', err);
      });
  };

  getFaqAvailability = async () => {
    await this.cardService
      .getSectionAvailability()
      .then((res) => {
        const { faq_enabled } = res;

        this.props.setFaqAvailability(faq_enabled);
      })
      .catch((err) => {
        console.log('Video error', err);
      });
  };

  getVideoStatusButton = async () => {
    await this.cardService
      .getVideoStatusButton()
      .then((res) => {
        this.setState((state) => ({
          ...state,
          videoStatusButton: res,
        }));
      })
      .catch((err) => {
        console.log('Video error', err);
      });
  };

  getDraft = async () => {
    const { setDraftCard } = this.props;
    const params = new URLSearchParams(this.props.history.location.search);
    let draft = splitArr(params.get('draft'));

    if (draft) {
      try {
        await this.cardService
          .getDraft(draft)
          .then((res) => {
            // console.log('DRAFT', res);
            setDraftCard(res);
          })
          .catch((err) => {
            console.log('Draft error', err);
          });

        // draft = JSON.parse(atob(draft));
        // // draft = JSON.parse(window.atob(draft));

        // setTimeout(() => {
        //   setDraftCard(draft);
        // }, 100);
      } catch {
        // console.log('ERROR atob(draft)')
      }
    }

    function splitArr(arr) {
      if (arr) {
        arr = arr.split(',');
      } else {
        arr = false;
      }

      return arr;
    }
  };

  getMenu = async () => {
    // getDropsMenu
    await this.cardService
      .getMenu()
      .then(async (res) => {
        let newRes = [...res];
        let categories = [];
        res.forEach((menu) => (categories = [...categories, ...menu.categories]));
        this.props.setAllCategories(categories);
        await Promise.all(
          res.map(async (item, i) =>
            item.categories.map(async (cat, index) => {
              if (cat.iconUrl.length && cat.iconUrl.indexOf('.svg')) {
                try {
                  const svg = await fetch(cat.iconUrl);
                  const svgIcon = await svg.text();
                  newRes[i].categories[index].svgIcon = svgIcon;
                } catch (err) {
                  console.log(err);
                }
              }
            })
          )
        );

        this.setState({
          featuredCategories: newRes,
        });
      })
      .catch((err) => {
        console.log('Menu error', err);
      });

    // get main menu
    await this.cardService
      .getMainMenu()
      .then((res) => {
        // console.log('MAIN MENU', res)
        this.props.setCategories(res);
      })
      .catch((err) => {
        console.log('Header: get main menu error:', err);
      });
  };

  mouseEnterLeaveHandler = (e, flag) => {
    const el = e.currentTarget;
    const activeClass = 'active';

    if (e && e.type !== 'touchstart') {
      e.stopPropagation();
      e.preventDefault();
    }

    switch (flag) {
      case 'enter':
        if (e && e.type !== 'touchstart') {
          el.classList.add(activeClass);
          if (el.parentNode.classList.contains('menu-bottom')) {
            const additionalActiveMenu = document.querySelectorAll(
              '.menu-item.menu-item-type-custom.menu-item-object-custom.menu-item-has-children.active'
            );
            if (additionalActiveMenu) {
              additionalActiveMenu.forEach((menuItem) => {
                hideRest(menuItem, true);
              });
            }
          }
          hideRest(el);
        } else {
          if (el === e.target.closest('li')) {
            el.classList.toggle(activeClass);
          }

          hideRest(el);
        }
        break;
      case 'leave':
        el.classList.remove(activeClass);
        break;
      default:
        el.classList.remove(activeClass);
    }

    function hideRest(el, closeWithCurrent = false) {
      const items = getSiblings(el);
      if (closeWithCurrent) {
        items.push(el);
      }
      items.forEach((item) => {
        const openedChild = item.querySelector('.menu-open-close .menu-item.active');
        if (openedChild) {
          openedChild.classList.remove('active');
        }
        if (closeWithCurrent || item !== el) {
          item.classList.remove('active');
        }
      });
    }

    function getSiblings(e) {
      let siblings = [];
      if (!e.parentNode) {
        return siblings;
      }
      let sibling = e.parentNode.firstChild;
      while (sibling) {
        if (sibling.nodeType === 1 && sibling !== e) {
          siblings.push(sibling);
        }
        sibling = sibling.nextSibling;
      }
      return siblings;
    }
  };

  onMenuOutsideClick = (e) => {
    const items = document.querySelectorAll(
      '.menu-bottom > .menu-item, .menu-open-close > .menu-item'
    );

    items.forEach((item) => {
      item.classList.remove('active');
    });
  };

  popupToggle = (e) => {
    e.preventDefault();

    this.props.toggleHeaderPopup();
  };

  openSearchForm = () => {
    this.setState(
      (state) => ({
        searchActive: true,
        menuActive: false,
      }),
      () => {
        document.body.classList.remove('menu-active');
      }
    );
  };

  closeSearchForm = () => {
    this.setState((state) => ({
      searchActive: false,
    }));
  };

  toggleMobileMenu = (e) => {
    e.preventDefault();

    if (!this.state.menuActive) {
      this.closeSearchForm();
    }

    this.setState(
      (state) => ({
        menuActive: !state.menuActive,
      }),
      () => {
        if (this.state.menuActive) {
          document.body.classList.add('menu-active');
        } else {
          document.body.classList.remove('menu-active');
        }
      }
    );
  };

  initStickyBlock = () => {
    const el = this.stickyBlock.current,
      stop = el.parentNode.offsetTop,
      docBody = document.documentElement || document.body.parentNode || document.body,
      hasOffset = window.pageYOffset !== undefined,
      activeClass = 'block-scrolled',
      scrollTop = hasOffset ? window.pageYOffset : docBody.scrollTop;

    if (scrollTop > stop) {
      el.classList.add(activeClass);
    } else {
      el.classList.remove(activeClass);
    }
  };

  sendLogout = async (e) => {
    if (e) {
      e.preventDefault();
    }

    await this.cardService
      .userLogout()
      .then((res) => {
        // console.log('res', res)
        if (res.revoked) {
          this.props.logoutUser();
          this.props.history.push('/');
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  render() {
    const { currentUser, isCustomMode, activeCategory, videoEnabled, faqEnabled, categories } =
      this.props;

    const { featuredCategories, videoStatusButton, searchActive, customDesignButton } = this.state;

    return (
      <>
        <header id="header" className="header">
          <div className="container">
            <div className="header-holder">
              <strong className="logo">
                <Link
                  to={isCustomMode ? '/custom-motion' : '/'}
                  className="custom-logo-link"
                  rel="home"
                >
                  <img
                    width="249"
                    height="245"
                    src={
                      isCustomMode
                        ? '/assets/images/logo-custom-motion.png'
                        : '/assets/images/I-hope-to-see-you-2-upd-2.png'
                    }
                    className="custom-logo"
                    alt="Ihopetoseeyou.com"
                  />
                </Link>
              </strong>
              <div className="header-info">
                <div className="text-right d-lg">
                  {videoEnabled && (
                    <a
                      href="#popup1"
                      className="video-nav-opener href-popup-opener"
                      onClick={this.popupToggle}
                    >
                      HOW IT WORKS
                    </a>
                  )}
                  {faqEnabled && (
                    <a href="#popup2" className="faq-link" onClick={this.popupToggle}>
                      FAQ
                    </a>
                  )}
                  <Link to="/contact-us">Contact Us</Link>
                </div>
                <div className="nav">
                  <SearchForm
                    isSearchActive={searchActive}
                    handleOpen={this.openSearchForm}
                    handleClose={this.closeSearchForm}
                  />
                  <a href="/" onClick={this.toggleMobileMenu} className="menu-opener">
                    <span>Menu</span>
                  </a>
                  <div className="main-menu-holder menu">
                    <div className="nav-holder">
                      <nav id="navbar" className="navbar">
                        <ul className="menu-open-close main-nav-holder">
                          {featuredCategories.map((item, i) => {
                            // console.log('cat', item)
                            if (!item.categories || item.categories.length < 1) {
                              return '';
                            }

                            return (
                              <li
                                key={i}
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children"
                                onMouseEnter={(e) => this.mouseEnterLeaveHandler(e, 'enter')}
                                onMouseLeave={(e) => this.mouseEnterLeaveHandler(e, 'leave')}
                                onTouchStart={(e) => this.mouseEnterLeaveHandler(e, 'enter')}
                              >
                                <span className="opener" onTouchEnd={(e) => e.preventDefault()}>
                                  {item.name}
                                </span>
                                <div className="menu-slide">
                                  <ul className="menu-open-close">
                                    {item.categories.map((cat) => {
                                      const iconPath = cat.iconUrl.split('/');
                                      const icon = iconPath[iconPath.length - 1];
                                      const url =
                                        window.location.hostname === 'localhost'
                                          ? `/assets/images/category/${icon}`
                                          : cat.iconUrl;
                                      return (
                                        <li
                                          key={cat.id}
                                          className="menu-item"
                                          onMouseEnter={(e) =>
                                            this.mouseEnterLeaveHandler(e, 'enter')
                                          }
                                          onMouseLeave={(e) =>
                                            this.mouseEnterLeaveHandler(e, 'leave')
                                          }
                                          onTouchStart={(e) =>
                                            this.mouseEnterLeaveHandler(e, 'enter')
                                          }
                                        >
                                          <Link
                                            to={`/category-of-card/${cat.slug}`}
                                            className={cat.categories.length ? 'opener' : ''}
                                            onTouchEnd={(e) => {
                                              if (cat.categories.length) {
                                                e.preventDefault();
                                              }
                                            }}
                                          >
                                            {cat.svgIcon ? (
                                              <div
                                                className="icon"
                                                dangerouslySetInnerHTML={{ __html: cat.svgIcon }}
                                              />
                                            ) : (
                                              ''
                                            )}
                                            {cat.name}
                                          </Link>
                                          {cat.categories && cat.categories.length > 0 ? (
                                            <div className="menu-slide">
                                              <ul className="menu-open-close">
                                                <li>
                                                  <Link
                                                    to={`/category-of-card/${cat.slug}`}
                                                    className="opener"
                                                  >
                                                    view all
                                                  </Link>
                                                </li>
                                                {cat.categories.map((subCat) => (
                                                  <>
                                                    {item.savedCategoriesArr.includes(
                                                      subCat.id
                                                    ) && (
                                                      <li key={subCat.slug}>
                                                        <Link
                                                          to={`/category-of-card/${cat.slug}?sub=${subCat.slug}`}
                                                          className="opener"
                                                        >
                                                          {subCat.name}
                                                        </Link>
                                                      </li>
                                                    )}
                                                  </>
                                                ))}
                                              </ul>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </nav>
                      {/* Custom motion link */}
                      {/* <Link to={isCustomMode ? '/' : "/custom-motion"} className="btn btn-danger">CUSTOM MOTION</Link> */}
                    </div>
                    <div className="sub-nav-holder">
                      {videoStatusButton?.new_design_button_enabled && (
                        <a
                          href={videoStatusButton?.new_design_button_link}
                          target="_blank"
                          className="btn btn-danger btn-header"
                        >
                          Custom design
                        </a>
                      )}
                      {videoStatusButton?.status_videos_button_enabled && (
                        <a
                          href={videoStatusButton?.status_videos_button_link}
                          target="_blank"
                          className="btn btn-fill btn-header"
                        >
                          Status videos
                        </a>
                      )}
                      <SearchForm
                        isSearchActive={searchActive}
                        handleOpen={this.openSearchForm}
                        handleClose={this.closeSearchForm}
                      />

                      <ul className="mob-sub-nav">
                        {videoEnabled && (
                          <li>
                            <a
                              href="#popup1"
                              className="video-nav-opener href-popup-opener"
                              onClick={this.popupToggle}
                            >
                              HOW IT WORKS
                            </a>
                          </li>
                        )}
                        {faqEnabled && (
                          <li>
                            <a href="#popup2" className="faq-link" onClick={this.popupToggle}>
                              FAQ
                            </a>
                          </li>
                        )}
                        <li>
                          <Link to="/contact-us">Contact Us</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-bottom sticky-holder" style={{ minHeight: '40px' }}>
            <div className="sticky-block" ref={(r) => (this.stickyBlock.current = r)}>
              <div className="container">
                <div className="nav">
                  <ul className="menu-bottom open-close">
                    {categories.map((cat, i) => {
                      const active = activeCategory === cat.slug;
                      const iconPath = cat.iconUrl.split('/');
                      const icon = iconPath[iconPath.length - 1];
                      const url =
                        window.location.hostname === 'localhost'
                          ? `/assets/images/category/${icon}`
                          : cat.iconUrl;
                      return (
                        <li
                          key={cat.id}
                          className={active ? 'menu-item active-page' : 'menu-item'}
                          onMouseEnter={(e) => this.mouseEnterLeaveHandler(e, 'enter')}
                          onMouseLeave={(e) => this.mouseEnterLeaveHandler(e, 'leave')}
                          onTouchStart={(e) => this.mouseEnterLeaveHandler(e, 'enter')}
                        >
                          {
                            <Link
                              to={`/category-of-card/${cat.slug}`}
                              className={cat.categories.length > 0 ? 'opener' : ''}
                              onTouchEnd={(e) => e.preventDefault()}
                            >
                              {cat.iconUrl ? (
                                <img className="item-icon" src={cat.iconUrl} alt={cat.name} />
                              ) : (
                                ''
                              )}
                              <span className="item-text">{cat.name}</span>
                            </Link>
                          }
                          {cat.categories.length > 0 ? (
                            <div className="slide">
                              <ul className="inner-nav">
                                {cat.categories.map((subCat) => {
                                  return (
                                    <li key={subCat.id} className="menu-item">
                                      <Link to={`/category-of-card/${cat.slug}?sub=${subCat.slug}`}>
                                        {subCat.name}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          ) : (
                            ''
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div id="header_placeholder"></div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    isCustomMode: state.isCustomMode,
    activeCategory: state.activeCategory,
    draftCard: state.draftCard,
    videoEnabled: state.videoEnabled,
    faqEnabled: state.faqEnabled,
    categories: state.categories,
  };
};

const mapDispatchToProps = {
  toggleHeaderPopup,
  logoutUser,
  setDraftCard,
  unsetDraftCard,
  setFaqAvailability,
  setVideoAvailability,
  setCategories,
  setAllCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
