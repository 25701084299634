import React from 'react';
import './mobile-preview.scss';

const MobilePreview = ({ open }) => {
  return (
    <div
      className={`mobile-preview-block ${open ? '' : 'hide'}`}
      style={{ backgroundImage: 'url(/assets/images/roll-up-banner.png)' }}
    >
      <div className="banner-content">
        <img
          src={'/assets/images/ihopetoseeyou-logo.png'}
          alt="I hope to see you"
          className="mobile-img"
        />
        <h1 className="banner-title">
          Digital invitations <br />{' '}
          <span className="white-text">
            for all the moments <br />
            that matter
          </span>
          .
        </h1>
      </div>
    </div>
  );
};

export default MobilePreview;
