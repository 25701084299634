import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { loginUser, logoutUser } from '../../actions';
import CardService from '../../services/cards-service';

class LostPasswordForm extends Component {
  cardService = new CardService();
  state = {
    email: '',
    loggedIn: false,
    success: false,
  };

  componentDidMount() {
    this.checkUserLogin();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser !== this.props.currentUser) {
      this.checkUserLogin();
    }
  }

  checkUserLogin = () => {
    if (this.props.currentUser) {
      this.setState({
        loggedIn: true,
      });
    }
  };

  inputChangeHandler = (e) => {
    const name = e.target.name,
      value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = this.state;

    await this.cardService
      .userForgotPass(email)
      .then((res) => {
        // console.log('Forgot pass result', res);
        this.setState({
          email: '',
          success: true,
        });
      })
      .catch((err) => {
        console.log('Forgot pass error', err);
      });
  };

  render() {
    const { email, loggedIn, success } = this.state;

    if (loggedIn) {
      return <Redirect to="/account" />;
    } else if (success) {
      return <Redirect to="/account-login" />;
    }

    return (
      <div className="inner-page-holder login-holder">
        <form onSubmit={this.handleSubmit}>
          <fieldset>
            <h3>Forgot Password?</h3>
            <p>We will email you a reset password link</p>
            <p>
              <label htmlFor="user_login">Please provide your e-mail address:</label>
              <input
                type="email"
                name="email"
                id="user_login"
                value={email}
                onChange={this.inputChangeHandler}
              />
            </p>
            <p>
              <input type="submit" value="Get New Password" className="button" id="submit" />
            </p>
          </fieldset>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = {
  loginUser,
  logoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(LostPasswordForm);
