import React from 'react';
import PreloaderSm from '../preloader-sm';
import successMail from './mail.png';
import failedMail from './mail-failed.png';
import './card-buy-block.scss';

const DraftPopup = React.forwardRef((props, ref) => (
  <div className="popup-open upd-popup-holder draft-popup" data-popup="draft" ref={ref}>
    <div
      className="upd-popup-inner"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          props.closePopup(e);
        }
      }}
    >
      <div className="upd-popup">
        <a href="/" className="close-popup" onClick={(e) => props.closePopup(e)}>
          Close
        </a>
        <div className="text-wrapper">
          {!props.draftEmailStatus ? (
            <form className="draft-form" onSubmit={props.saveDraft}>
              <div className="info-block">
                <h4>Save your draft. Finish Later</h4>
                <p>
                  Please provide your email address and we will email you a link to this card in
                  current setup. You’ll be able to continue setting up later.
                </p>
              </div>
              <div className="form-row">
                <input
                  type="email"
                  name="draft_email"
                  placeholder="Your Email Address"
                  className="form-control"
                  value={props.draft_email}
                  onChange={props.inputChangeHandler}
                />
                <input type="submit" value="Send" className="btn btn-sm btn-fill" />
                {props.loading ? <PreloaderSm /> : ''}
              </div>
            </form>
          ) : props.draftSuccess ? (
            <div className="draft-success" style={{ display: 'block', paddingLeft: '0' }}>
              <a href="/" className="close popup-opener" onClick={props.closePopup}>
                Close
              </a>
              <div className="img-block">
                <img src={successMail} alt="success" />
              </div>
              <h4>Your draft has been saved!</h4>
              <p>An email has been sent to the email address you have provided.</p>
            </div>
          ) : (
            <div className="draft-fail">
              <a href="/" className="close popup-opener" onClick={props.closePopup}>
                Close
              </a>
              <div className="img-block">
                <img src={failedMail} alt="fail" />
              </div>
              <h4>Your draft hasn't been saved!</h4>
              <p>Please try later</p>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
));

export default DraftPopup;
