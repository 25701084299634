import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Keyboard from 'react-simple-keyboard';
import { setActiveCategory, unsetActiveCategory } from '../../actions';
import CardService from '../../services/cards-service';
import CardAddImage from '../card-add-image';
import CardBuyBlock from '../card-buy-block';
import CardInputField from '../card-input-field';
import CardMusicBlock from '../card-music-block';
import Preloader from '../preloader';

import 'react-simple-keyboard/build/css/index.css';
import PreviewBlock from '../preview-block/preview-block';
import ThanksPopup from './ThanksPopup';
import './card-item.scss';

class CardItem extends Component {
  cardService = new CardService();
  state = {
    windowWidth: null,
    loading: true,
    activeCardId: '',
    initialCard: null,
    card: null,
    cardPrice: null,
    musicPrice: 0,
    notFound: false,
    showHiddenFields: false,
    activeBgImage: 'https://ihopetoseeyou.com/wp-content/uploads/2020/11/Z-125.01.jpg',
    bgVariantsList: [
      {
        title: 'Bg 1',
        img: 'https://ihopetoseeyou.com/wp-content/uploads/2020/11/Z-125.01.jpg',
        active: true,
      },
      {
        title: 'Bg 2',
        img: 'https://ihopetoseeyou.com/wp-content/uploads/2021/02/Z-127.jpg',
        active: false,
      },
      {
        title: 'Bg 3',
        img: 'https://ihopetoseeyou.com/wp-content/uploads/2021/01/BM-118.jpg',
        active: false,
      },
      {
        title: 'Bg 4',
        img: 'https://ihopetoseeyou.com/wp-content/uploads/2020/11/Z-125.01.jpg',
        active: false,
      },
      {
        title: 'Bg 5',
        img: 'https://ihopetoseeyou.com/wp-content/uploads/2021/02/Z-127.jpg',
        active: false,
      },
      {
        title: 'Bg 6',
        img: 'https://ihopetoseeyou.com/wp-content/uploads/2021/01/BM-118.jpg',
        active: false,
      },
    ],
    showKeyboard: false,
    keyboardLang: 'hebrew',
    keyboardPos: { top: 0, left: 0, width: 300 },
    inputIdForKeyboard: 0,
    musicList: [],
    cardPaid: false,
    isBgToggle: false,
    draftMusic: false,
    imageSizes: {
      width: 2500,
      height: 3300,
    },
    isWhatsAppNotificationEnabled: false,
    activePopup: false,
    email: null,
    defaultSecondImage: null,
    useDefaultImage: true,
  };
  keyboard = React.createRef();

  componentDidMount() {
    this.getCard();
    this.setWindowWidth();
    window.addEventListener('resize', this.setWindowWidth);
  }

  setWindowWidth = () => {
    const windowWidth = window.innerWidth;
    this.setState((state) => ({
      ...state,
      windowWidth,
    }));
  };

  componentDidUpdate(prevProps) {
    if (prevProps.card !== this.props.card) {
      this.getCard();
    }
  }

  componentDidCatch() {
    this.setState({
      error: true,
    });
  }

  componentWillUnmount() {
    this.toggleActiveCategory(false);
    window.removeEventListener('resize', this.setWindowWidth);
  }

  setLogoProportion = (x, y) => {
    const { card } = this.state;
    const updCard = { ...card };
    updCard.logo_proportion = {
      x,
      y,
    };
    this.setState((state) => ({
      card: updCard,
    }));
  };

  toggleActiveCategory = (set) => {
    const { setActiveCategory, unsetActiveCategory } = this.props;

    if (set) {
      setActiveCategory('s');
    } else {
      unsetActiveCategory();
    }
  };

  setEmail = (email) => {
    this.setState({
      email,
    });
  };

  getCard = async () => {
    this.setState({
      loading: true,
    });

    const { card, draftCard } = this.props;

    await this.cardService
      .getCard(card)
      .then((res) => {
        const { cardAudios, cardFolders, cardDetails } = res;

        const storageCard = sessionStorage.getItem(`${card}`);
        const newCard = {
          should_reverse_images: res?.cardDetails?.should_reverse_images ?? 0,
          allow_user_upload_image: cardDetails?.allow_user_upload_image ?? 0,
          fields: [...cardDetails.cardAttributes],
          cardId: card,
          initCardData: res,
        };

        if (draftCard && draftCard.cardAttributes) {
          newCard.fields.forEach((item, i) => {
            item.text = draftCard.cardAttributes[i].text;
          });

          if (draftCard.audio_id) {
            this.setState({
              draftMusic: draftCard.audio_id,
            });
            // this.setCheckedMusic(draftCard.audio_id);
          }
        }

        if (cardAudios?.length || cardFolders?.length) {
          newCard.audio_id = '';
          this.setState({
            musicList: { cardAudios, cardFolders },
          });
        }

        if (cardDetails.has_logo) {
          newCard.has_logo = cardDetails.has_logo;
          newCard.logoDetails = {
            image: null,
            width: 150,
            height: 150,
            posX: 0,
            posY: 0,
          };
        }

        if (cardDetails.second_image) {
          let imageUrl = cardDetails.secondImageUrl;
          imageUrl = encodeURI(imageUrl);

          this.getBase64Image(imageUrl, (url) => {
            let updCard = { ...this.state.card };
            updCard.second_image.url = url;

            this.setState((state) => ({
              card: { ...updCard },
              defaultSecondImage: url,
            }));
          });

          newCard.second_image = {
            url: cardDetails.secondImageUrl,
            height: cardDetails.second_image_height,
            width: cardDetails.second_image_width,
            posx: cardDetails.second_image_position_x,
            posy: cardDetails.second_image_position_y,
            rotation: cardDetails.second_image_rotation,
          };
        }

        newCard.fields.forEach((item) => {
          item.defaultText = item.text || '';
          item.initial_text_lang = item.text_lang;
        });

        if (storageCard) {
          const storageCardJSON = JSON.parse(storageCard);
          newCard.fields = [...storageCardJSON.fields];
        }

        this.setState({
          activeCardId: card,
          initialCard: res,
          card: newCard,
          cardPrice: res.price,
          loading: false,
          activeBgImage: cardDetails.mainImageUrl,
          imageSizes: {
            width: cardDetails.mainImageDimensions.width || 2500,
            height: cardDetails.mainImageDimensions.height || 3300,
          },
        });

        this.toggleActiveCategory(true);
      })
      .catch((err) => {
        console.log('Get card error', err);
        this.setState({
          // notFound: true,
          loading: false,
        });
      });
  };

  changeHandler = (newText, id) => {
    const { card } = this.state;
    const updCard = { ...card };
    const onlyHebrewPattern = new RegExp(/^[\u0590-\u05FF ~,.:;/'’"?!^@&#$%*()_`=+{}|[\]<>\d-]+$/i);
    updCard.fields[id].text = newText;
    updCard.fields[id].text_lang = onlyHebrewPattern.test(newText) ? 'hebrew' : 'english';

    this.setState(
      (state) => ({
        card: { ...updCard },
      }),
      () => {
        this.setKeyboardInput();
        sessionStorage.setItem(this.state.card.cardId, JSON.stringify(this.state.card));
      }
    );
  };

  whatsAppNotificationHandler = () => {
    const { isWhatsAppNotificationEnabled } = this.state;
    this.setState((state) => ({
      isWhatsAppNotificationEnabled: !isWhatsAppNotificationEnabled,
    }));
  };

  whatsAppPhoneNumberHandler = (value) => {
    this.setState((state) => ({
      whatsappPhoneNumber: value,
    }));
  };

  inputViewToggle = (flag) => {
    this.setState({
      showHiddenFields: flag,
      showKeyboard: false,
    });
  };

  toggleInputVal = (e, id, defVal) => {
    if (e) {
      e.preventDefault();
    }

    const btn = e.currentTarget,
      resetClass = 'reset-state',
      clearClass = 'clear-state';

    if (btn.classList.contains(clearClass)) {
      this.changeHandler('', id);
    } else if (btn.classList.contains(resetClass)) {
      this.changeHandler(defVal, id);
    }
  };

  bgVariantsToggle = (e) => {
    e.preventDefault();

    const opener = e.currentTarget,
      holder = opener.parentElement,
      activeClass = 'drop-active';

    holder.classList.toggle(activeClass);
  };

  setActiveBg = (e, id) => {
    if (e) {
      e.preventDefault();
    }

    const { bgVariantsList } = this.state;

    const updList = bgVariantsList.map((item, i) => {
      if (i === id) {
        item.active = true;

        this.setState({
          activeBgImage: item.img,
        });
      } else {
        item.active = false;
      }

      return item;
    });

    this.setState({
      bgVariantsList: updList,
    });
  };

  setCheckedMusic = (musicId) => {
    const { card } = this.state;
    const updCard = { ...card };

    updCard.audio_id = musicId || null;

    let addPrice = 0;

    if (updCard.audio_id) {
      addPrice = updCard.initCardData.music_price || 5;
    }

    this.setState({
      card: { ...updCard },
      musicPrice: addPrice,
    });
  };

  toggleKeyboardView = (e, id, lang) => {
    e.preventDefault();

    if (!this.state.showKeyboard) {
      const target = e.currentTarget,
        inputHolder = target.parentElement,
        holderHeight = inputHolder.offsetHeight,
        holderWidth = inputHolder.offsetWidth,
        holderOffset = offset(inputHolder);

      this.setState(
        (state) => ({
          showKeyboard: !state.showKeyboard,
          inputIdForKeyboard: id,
          keyboardLang: lang,
          keyboardPos: {
            top: holderHeight + holderOffset.top,
            left: holderOffset.left,
            width: holderWidth,
          },
        }),
        this.setKeyboardInput
      );
    } else {
      this.setState((state) => ({
        showKeyboard: !state.showKeyboard,
        keyboardLang: lang,
      }));
    }

    // get input position for keyboard
    function offset(el) {
      var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }
  };

  setKeyboardInput = () => {
    const { card, inputIdForKeyboard } = this.state;
    this.keyboard.current.setInput(card.fields[inputIdForKeyboard].text);
  };

  keyBoardOnChange = (input) => {
    const { inputIdForKeyboard } = this.state;

    this.changeHandler(input, inputIdForKeyboard);
  };

  setAddImg = (path) => {
    const { card, defaultSecondImage } = this.state;
    const updCard = { ...card };
    if (path) {
      updCard.second_image.url = path;
      this.setState((state) => ({
        ...state,
        card: { ...updCard },
        useDefaultImage: false,
      }));
    } else {
      updCard.second_image.url = defaultSecondImage;

      this.setState((state) => ({
        card: { ...updCard },
        useDefaultImage: true,
      }));
    }
  };

  setLogoSize = (width, height) => {
    const { card } = this.state;
    const updCard = { ...card };
    updCard.logoDetails.width = width;
    updCard.logoDetails.height = height;
    this.setState((state) => ({
      ...state,
      card: { ...updCard },
    }));
  };

  setAddLogo = (path, width, height) => {
    const { card } = this.state;
    const updCard = { ...card };
    updCard.logoDetails.image = path;
    this.setState((state) => ({
      ...state,
      card: { ...updCard },
    }));
    this.setLogoSize(150, height * (150 / width));
  };

  changeLogoPosition = (x, y) => {
    const { card } = this.state;
    const updCard = { ...card };
    updCard.logoDetails.posX = x;
    updCard.logoDetails.posY = y;
    this.setState((state) => ({
      ...state,
      card: { ...updCard },
    }));
  };

  setPaid = () => {
    sessionStorage.clear();
    this.setState({
      cardPaid: true,
      activePopup: true,
    });
  };

  closePopup = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      activePopup: false,
      stripeError: false,
    });
  };

  getBase64Image = async (src, callback) => {
    const response = await fetch(src);
    const blob = await response.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        callback(base64data);
      };
    });
  };

  onKeyPress = (button) => {
    if (button === '{עבר}') {
      this.setState((prevState) => ({
        ...prevState,
        keyboardLang: 'hebrew',
      }));
    }

    if (button === '{ENG}') {
      this.setState((prevState) => ({
        ...prevState,
        keyboardLang: 'english',
      }));
    }

    if (button === '{shift}') {
      this.setState((prevState) => ({
        isShifted: !prevState.isShifted,
      }));
    }
  };

  isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0;
  };

  render() {
    const {
      loading,
      notFound,
      initialCard,
      card,
      cardPrice,
      musicPrice,
      showHiddenFields,
      bgVariantsList,
      activeBgImage,
      showKeyboard,
      keyboardPos,
      musicList,
      cardPaid,
      isBgToggle,
      draftMusic,
      imageSizes,
      useDefaultImage,
    } = this.state;

    const keyboarLayout = {
      default: [
        '1 2 3 4 5 6 7 8 9 0 - = {bksp}',
        "/ ' \u05e7 \u05e8 \u05d0 \u05d8 \u05d5 \u05df \u05dd \u05e4 ] [ :",
        '\u05e9 \u05d3 \u05d2 \u05db \u05e2 \u05d9 \u05d7 \u05dc \u05da \u05e3 ,',
        '\u05d6 \u05e1 \u05d1 \u05d4 \u05e0 \u05de \u05e6 \u05ea \u05e5 . "',
        '{shift} @ {space} {ENG}',
      ],
      shift: [
        '! @ # $ % ^ & * ( ) _ + {bksp}',
        '? " \u05e7 \u05e8 \u05d0 \u05d8 \u05d5 \u05df \u05dd \u05e4 } { |',
        '\u05e9 \u05d3 \u05d2 \u05db \u05e2 \u05d9 \u05d7 \u05dc \u05da \u05e3 ,',
        '\u05d6 \u05e1 \u05d1 \u05d4 \u05e0 \u05de \u05e6 \u05ea \u05e5 . "',
        '{shift} @ {space} {ENG}',
      ],
    };

    const keyboardLayoutEng = {
      default: [
        '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
        'q w e r t y u i o p [ ] \\',
        "a s d f g h j k l ; '",
        'z x c v b n m , . /',
        '{shift} @ {space} {עבר}',
      ],
      shift: [
        '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
        'Q W E R T Y U I O P { } |',
        'A S D F G H J K L : "',
        'Z X C V B N M < > ?',
        '{shift} @ {space} {עבר}',
      ],
    };

    const layout = this.state.keyboardLang === 'hebrew' ? keyboarLayout : keyboardLayoutEng;

    if (notFound) {
      return (
        <>
          <Redirect to="/404" />
        </>
      );
    }

    if (loading) {
      return <Preloader />;
    }

    let categoryTitle = '';
    card.initCardData.categories.forEach((item) => {
      if (categoryTitle.length > 0) {
        categoryTitle += ' + ' + item.name;
      } else {
        categoryTitle += item.name;
      }
    });

    let hiddenfields = false;
    for (let i = 0; i < card.fields.length; i++) {
      if (+card.fields[i].isHidden) {
        hiddenfields = true;
      }
    }

    return (
      <section className="detail-section detail-section-upd">
        <div className="container">
          <div className="editor-holder">
            <div className="page-heading">
              <h1 className="block-header">{categoryTitle.toUpperCase()}</h1>
              <div className="price-holder">
                <strong>#{initialCard && initialCard.name}</strong>
              </div>
            </div>
            <div
              id="detail-form"
              action="#"
              className={!cardPaid ? 'detail-holder' : 'detail-holder thanks'}
              encType="multipart/form-data"
              autoComplete="off"
            >
              <PreviewBlock
                imageSizes={imageSizes}
                card={card}
                activeBgImage={activeBgImage}
                isBgToggle={isBgToggle}
                bgVariantsList={bgVariantsList}
                changeLogoPosition={this.changeLogoPosition}
                setLogoSize={this.setLogoSize}
                setLogoProportion={this.setLogoProportion}
              />

              <div className="details-block">
                <div className="editor-note-holder">
                  <strong>Start editing</strong>
                  <span>See your information appear live as you enter them</span>
                </div>
                <div className="editor-music-holder">
                  {this.state.windowWidth > 768 && !this.isObjectEmpty(musicList) ? (
                    <CardMusicBlock
                      musicList={musicList}
                      setCheckedMusic={this.setCheckedMusic}
                      checkedDraftMusic={draftMusic}
                      dropTitle={'Add Music $' + (initialCard.music_price || 5)}
                    />
                  ) : (
                    ''
                  )}
                  {hiddenfields ? (
                    <div className="hidden-fields-holder">
                      <label>
                        <span className="fake-checkbox-holder">
                          <input
                            type="checkbox"
                            name="hidden_fields"
                            className="hide-input hidden-fields-checkbox"
                            onClick={(e) => this.inputViewToggle(e.target.checked)}
                          />
                          <span className="fake-checkbox"></span>
                        </span>
                        Enable hidden fields
                      </label>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className={'image-upload-holder'}>
                  {card?.allow_user_upload_image ? (
                    <CardAddImage
                      addImg={card.second_image}
                      setAddImg={this.setAddImg}
                      useDefaultImg={useDefaultImage}
                      isLogo={false}
                    />
                  ) : (
                    ''
                  )}
                  {card.has_logo && (
                    <CardAddImage
                      addImg={card.logoDetails}
                      setAddImg={this.setAddLogo}
                      useDefaultImg={!card.logoDetails.image}
                      isLogo={true}
                    />
                  )}
                </div>
                <div
                  className={showHiddenFields ? 'content-details show-hiddens' : 'content-details'}
                >
                  {card
                    ? card.fields.map((item, i) => (
                        <CardInputField
                          key={i}
                          item={item}
                          index={i}
                          changeHandler={this.changeHandler}
                          toggleInputVal={this.toggleInputVal}
                          keyboard={item.text_lang}
                          lang={this.state.keyboardLang}
                          toggleKeyboardView={this.toggleKeyboardView}
                        />
                      ))
                    : ''}
                </div>
                <div className="content-details">
                  {this.state.windowWidth < 768 && !this.isObjectEmpty(musicList) ? (
                    <CardMusicBlock
                      musicList={musicList}
                      setCheckedMusic={this.setCheckedMusic}
                      checkedDraftMusic={draftMusic}
                      dropTitle={'Add Music $' + (initialCard.music_price || 5)}
                    />
                  ) : (
                    ''
                  )}
                  <CardBuyBlock
                    product={card}
                    draft
                    btnTitle={'Buy Card Now'}
                    price={cardPrice + musicPrice}
                    cardName={initialCard.name || 'card'}
                    onSuccess={this.setPaid}
                    setEmail={this.setEmail}
                  />
                </div>
              </div>
              {this.state.activePopup && (
                <ThanksPopup closePopup={this.closePopup} email={this.state.email} />
              )}
            </div>
          </div>
        </div>

        <div
          className="input-keyboard"
          style={
            showKeyboard
              ? { opacity: '1', visibility: 'visible' }
              : {
                  opacity: '0',
                  visibility: 'hidden',
                }
          }
        >
          <div className="input-keyboard-block" style={keyboardPos}>
            <Keyboard
              keyboardRef={(r) => (this.keyboard.current = r)}
              onChange={this.keyBoardOnChange}
              onKeyPress={this.onKeyPress}
              layoutName={this.state.isShifted ? 'shift' : 'default'}
              layout={layout}
              display={{
                '{עבר}': 'עבר',
                '{ENG}': 'ENG',
                '{space}': ' ',
                '{bksp}': 'backspace',
                '{shift}': 'shift',
              }}
            />
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeCategory: state.activeCategory,
    draftCard: state.draftCard,
  };
};

const mapDispatchToProps = {
  setActiveCategory,
  unsetActiveCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardItem);
