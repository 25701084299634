import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ContactFooter from '../contact-footer';
import { connect } from 'react-redux';
import { toggleHeaderPopup } from '../../actions';
import CardService from '../../services/cards-service';
import * as path from 'path';
import './footer.scss';

class Footer extends Component {
  cardService = new CardService();

  state = {
    menu: [],
  };

  getMenu = async () => {
    // getFooterMenu
    await this.cardService
      .getFooterMenu()
      .then((res) => {
        const updatesRes = res.map((item) => {
          if (
            item.url.indexOf(window.location.hostname) > -1 ||
            item.url.indexOf('https://dev.ihopetoseeyou.com/') > -1
          ) {
            let pathComponents = item.url.match(/([^\/]+)/g).splice(2);
            return {
              ...item,
              url: `/${pathComponents.join('/')}`,
              isInternal: true,
            };
          } else {
            return {
              ...item,
              isInternal: false,
            };
          }
        });
        this.setState({
          menu: updatesRes,
        });
      })
      .catch((err) => {
        console.log('Menu error', err);
      });
  };

  componentDidMount() {
    this.getMenu();
  }

  onClickLink = (e, url) => {
    if (url.toLowerCase().indexOf('popup') > -1) {
      e.preventDefault();
      this.props.toggleHeaderPopup();
    }
  };

  render() {
    return (
      <>
        <ContactFooter />
        <footer id="footer" className="upd_footer">
          <div className="footer-top-block">
            <div className="container">
              <div className="text-block">
                <strong className="logo">
                  <a href="/" className="custom-logo-link" rel="home">
                    <img
                      width="249"
                      height="245"
                      src={'/assets/images/logo.png'}
                      className="custom-logo"
                      alt="Ihopetoseeyou.com"
                    />
                  </a>
                </strong>
                <div className="text-holder">
                  <p>
                    Inviting your friends and family to your{' '}
                    <strong>"Simcha" shouldn't be a hassle</strong>.
                  </p>
                  <p>
                    Whether you're making a{' '}
                    <strong>Zucher, Vachnacht, Kiddish, Bar/Bat Mitzvah, Wedding, etc</strong>.
                    Create beautiful custom invitations on our user-friendly website with hundreds
                    of templates to choose from with just a few clicks.
                  </p>
                  <p>
                    <strong>And say "I hope to see you" by my "Simcha" with confidence.</strong>
                  </p>
                </div>
              </div>
              <ul className="footer-nav-upd">
                {this.state.menu.map((item) => (
                  <li key={item.id}>
                    {item.isInternal ? (
                      <Link to={item.url} onClick={(e) => this.onClickLink(e, item.url)}>
                        {item.title}
                      </Link>
                    ) : (
                      <a href={item.url}>{item.title}</a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="footer-bottom-block">
            <div className="container">
              <div className="copyright-upd-holder">
                <div className="network-upd-holder">
                  <strong>Follow us:</strong>
                  <ul className="network-upd">
                    <li>
                      <a href="https://wa.me/17186125052" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-whatsapp"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/ihopetoseeyou_com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    {/* <li><Link to="/"><i className="fab fa-whatsapp"></i></Link></li>
                  <li><Link to="/"><i className="fab fa-instagram"></i></Link></li> */}
                  </ul>
                </div>
                <div className="copyright-upd">
                  <p>
                    © 2023 <Link to="/">ihopetoseeyou.com</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    popupActive: state.headerPopupActive,
  };
};

const mapDispatchToProps = {
  toggleHeaderPopup,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
