import React from 'react';
import { Link } from 'react-router-dom';
import './contact-footer.scss';

const ContactFooter = () => {
  return (
    <section className="contact-details-section">
      <div className="contact-details-block">
        <div className="container">
          <div className="section-heading">
            <h2 className="section-title">You're human, so are we</h2>
            <div className="heading-note">
              <p>Have an issue with your card or need modifications? We are here to help!</p>
            </div>
          </div>
          <ul className="contact-details-list">
            <li>
              <a href="https://wa.me/17186125052" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-whatsapp"></i> WhatsApp us
              </a>
            </li>
            <li>
              <a href="mailto:orders@ihopetoseeyou.com" className="underlined-text">
                <i className="far fa-envelope"></i> orders@ihopetoseeyou.com
              </a>
            </li>
            <li>
              <a href="tel:7186125052">
                <i className="fas fa-phone-alt"></i> 718-612-5052
              </a>
            </li>
            <li>
              <Link to="/">
                <img src={'/assets/images/icon-faq.svg'} alt="faq" className="icon-img" /> FAQ
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default ContactFooter;
